import React, {Component} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import "../../assets/scss/core/Settings.scss";
import Icon from "./Icon";
import Switch from "./Switch";
import {withCookies} from "react-cookie";
import {__} from "../../helpers/multilingual";

class SettingsItem extends Component {
  render() {
    return (
      <div className="SettingsItem" onClick={this.props.onClick}>
        <p>
          {this.props.children}
          <Icon name="chevron-right" width="21px" height="21px" />
        </p>
      </div>
    );
  }
}

class Settings extends Component {
  constructor(props) {
    super(props);
    const {context} = props;
    var smt = this.props.cookies.get("smt");
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    this.state = {
      currentPage: 0,
      move: false,
      pages: [
        {
          title: __("Einstellungen", context),
          content: (
            <>
              <SettingsItem
                onClick={() => this.setState({move: true, currentPage: 1})}
              >
                {__("Datenschutz", context)}
              </SettingsItem>
            </>
          )
        },
        {
          title: __("Datenschutz", context),
          content: (
            <>
              <div>
                <Switch
                  onChange={v => {
                    smt.statistics.ga = v;
                    this.props.cookies.set("smt", smt, {
                      path: "/",
                      expires: cookieDate
                    });
                  }}
                  value={
                    smt.statistics && smt.statistics.ga
                      ? smt.statistics.ga
                      : false
                  }
                />{" "}
                Google Analytics
              </div>
            </>
          )
        }
      ]
    };
  }
  render() {
    return (
      <>
        {this.state.move && (
          <div className="back" onClick={() => this.setState({move: false})}>
            <Icon
              name="arrow-left"
              width="30px"
              height="30px"
              style={{width: "14px", height: "14px"}}
            />
          </div>
        )}
        <h3 className="SettingsTitle">
          {!this.state.move
            ? this.state.pages[0].title
            : this.state.pages[this.state.currentPage].title}
        </h3>
        <div className={classNames("cube", {turn: this.state.move})}>
          <div className="Settings">{this.state.pages[0].content}</div>
          <div className="Settings">
            {this.state.pages[this.state.currentPage].content}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(reduxState) {
  return {context: reduxState.multilingual.translations};
}
export default withCookies(connect(mapStateToProps, {})(Settings));
