import React, {Component} from "react";
import {connect} from "react-redux";
import "../assets/scss/QuantityOption.scss";
import Icon from "./core/Icon";
import {
  requestUpdateProduct,
  requestRemoveProduct
} from "../store/product/actions";
import {track} from "../helpers/ga";
import {__} from "../helpers/multilingual";

class QuantityOption extends Component {
  constructor(props) {
    super(props);
    var quantity = 1;
    if (Object.entries(this.props.cat).length) {
      var sec = this.props.section.toLowerCase();
      if (sec === "sprachassistenten") {
        sec = "smart_assistants";
      }
      var {slug} = this.props.data;
      if (slug.split("_").length > 1) {
        slug = slug.split("_")[1];
      }
      let id = this.props.cat[sec].other_products.products.findIndex(
        product => product.category_id === slug
      );
      quantity = this.props.cat[sec].other_products.products[id].quantity;
    }
    this.state = {
      quantity: parseInt(quantity)
    };
  }
  componentDidMount() {}
  onFocusOut = e => {
    var limit =
      this.props.data.name.includes("W") && this.props.section === "Beleuchtung"
        ? 3
        : 30;
    if (isNaN(this.state.quantity)) {
      this.setState({quantity: 1});
    }
    if (this.state.quantity > limit) {
      this.setState({quantity: limit});
    }
  };
  onChangeQuantity = (e, n = 0, d = 0) => {
    var limit =
      this.props.data.name.includes("W") && this.props.section === "Beleuchtung"
        ? 2
        : 29;
    if (d === 0) {
      this.setState({quantity: e.target.valueAsNumber}, () =>
        this.updateRequest()
      );
    } else {
      if (this.state.quantity + parseInt(n) <= 0) {
        this.removeRequest();
      }
      if (n === -1 && this.state.quantity < 1) {
        return;
      }
      var q = this.state.quantity;
      if (q > limit) {
        q = limit;
      }
      this.setState({quantity: q + parseInt(n)}, () => this.updateRequest());
    }
    track(
      "Quantity Option",
      n === -1 ? "Decrease" : n === 1 ? "Increase" : "Wheel",
      this.props.data.name,
      d === 0 ? e.target.valueAsNumber : n
    );
    for (var i of document.querySelectorAll(".category")) {
      i.classList.remove("marked");
    }
    e.target.parentNode.parentNode.parentNode.parentNode.classList.add(
      "marked"
    );
  };
  updateRequest = () => {
    this.props.requestUpdateProduct(
      this.props.data.slug,
      this.state.quantity,
      this.props.section
    );
  };
  removeRequest = () => {
    track("Quantity Option", "Remove", this.props.data.name, 1);
    this.props.callback();
  };
  render() {
    const {context} = this.props;
    return (
      <div
        className="option"
        data-slug={this.props.data.slug}
        data-quantity={this.state.quantity}
        data-section={this.props.section}
      >
        <div>
          <img src={this.props.data.images} alt="" />
        </div>
        <div className="title">{__(this.props.data.name, context)}</div>
        <div className="quantity">
          <div
            className="quantityDec"
            onClick={e => {
              this.onChangeQuantity(e, -1, 1);
            }}
          >
            -
          </div>
          <input
            className="quantityField"
            type="number"
            min="0"
            max={
              this.props.data.name.includes("W") &&
              this.props.section === "Beleuchtung"
                ? 3
                : 30
            }
            value={this.state.quantity}
            onChange={this.onChangeQuantity}
            onBlur={this.onFocusOut}
          />
          <div
            className="quantityInc"
            onClick={e => {
              this.onChangeQuantity(e, 1, 1);
            }}
          >
            +
          </div>
        </div>
        <div
          className="remove"
          onClick={e => {
            this.removeRequest();
          }}
        >
          <Icon
            name="times"
            width="25px"
            height="25px"
            style={{width: "14px", height: "14px"}}
          />
        </div>
      </div>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    cat: reduxState.product.request.product_answers.categories,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {
  requestUpdateProduct,
  requestRemoveProduct
})(QuantityOption);
