import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/scss/core/Badge.scss';

class Badge extends Component {
  render() {
    return <div className='Badge'>
      {this.props.children}
    </div>;
  }
}
function mapStateToProps(reduxState){
  return {
  }
}
export default connect(mapStateToProps,{})(Badge);
