import React, {Component} from "react";
import {connect} from "react-redux";
import "../assets/scss/Product.scss";
import {getColorHtml} from "../helpers/colors";
import {getStarHtml} from "../helpers/star";
import Modal from "./core/Modal";
import Accordion, {AccordionHeader} from "./core/Accordion";
import Icon from "./core/Icon";
import Button from "./core/Button";
import Badge from "./core/Badge";
import classNames from "classnames";
import {toggleProduct, toggleAddonProduct} from "../store/product/actions";
import {track} from "../helpers/ga";
import Swiper from "./core/Swiper";
import {__} from "../helpers/multilingual";

class Product extends Component {
  state = {
    modal: false,
    image: 0,
    selected: false
  };
  component = React.createRef();
  render() {
    if (this.state.modal) {
      this.component.current
        .closest(".Wide")
        .querySelector(".NavBar").style.zIndex = 0;
    }
    const {data, badge, buy, context} = this.props;
    const params = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      keyboard: {
        enabled: true
      }
    };
    const addon = this.props.addon || false;
    const modVals = !addon ? data : data.add_on_product;
    if (modVals.affiliate_link) {
      modVals.affiliate_link = modVals.affiliate_link.replace(
        /uismarthome0c-21/,
        buy
      );
    }
    var rating = data.rating;
    var starRenderer = [];
    for (let i = 0; i < 5; i++) {
      starRenderer.push(
        <span key={data.ean_number + "_stars_" + i}>{getStarHtml(rating)}</span>
      );
      rating--;
    }
    return (
      <div
        className={classNames(
          "Product",
          {marked: !addon && data.selected},
          this.props.className
        )}
        ref={this.component}
      >
        {!addon && (
          <>
            <div
              className="image"
              onClick={() => {
                this.setState({modal: true});
                track("Product", "Show", data.name, 1);
              }}
            >
              <img src={data.images[0]} alt="" />
              {data.selected ? <Icon name="check-circle" /> : ""}
            </div>
            <div className="details">
              <div className="title">
                <span
                  onClick={() => {
                    this.setState({modal: true});
                    track("Product", "Show", data.name, 1);
                  }}
                >
                  {__(data.name, context)}
                </span>
              </div>
              <div className="information">
                {data.colors && data.colors.length > 1 ? (
                  <div className="colorContainer">
                    <span>{__("Farben:", context)}</span>
                    <div className="colors">
                      {data.colors.map((d, i) => {
                        return (
                          <span key={data.ean_number + "_" + i}>
                            {getColorHtml(d.color_code)}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="starContainer">{starRenderer}</div>
                <div className="quantity">
                  <span>{__("Anzahl:", context)} </span>
                  {data.quantity}
                </div>
                <div className="price">
                  <p>
                    {new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR"
                    }).format(data.price)}
                  </p>
                  <Icon
                    name="clone"
                    width="50px"
                    height="50px"
                    style={{width: "16px", height: "16px"}}
                    onClick={() => {
                      this.setState({modal: true});
                      track("Product", "Show", data.name, 1);
                    }}
                  />
                </div>
                {this.props.select === "custom" ? (
                  <div className="select">
                    <Button
                      mode="secondary"
                      onClick={() => {
                        let section_name = data.section;
                        if (!data.section) {
                          section_name = "smart_assistants";
                        }
                        this.props.toggleProduct(data.ean_number, section_name);
                        if (
                          this.props.select === "custom" &&
                          data.add_on_product &&
                          data.add_on_product.selected
                        ) {
                          this.props.toggleAddonProduct(
                            data.ean_number,
                            data.section,
                            this.props.select
                          );
                          track(
                            "Addon Product",
                            data.add_on_product.selected
                              ? "Unselect"
                              : "Select",
                            data.add_on_product.name,
                            data.add_on_product.selected ? -1 : 1
                          );
                        }
                        setTimeout(() => this.props.onClick(), 100);
                        track(
                          "Product",
                          data.selected ? "Unselect" : "Select",
                          data.name,
                          data.selected ? -1 : 1
                        );
                      }}
                    >
                      {data.selected
                        ? __("Entfernen", context)
                        : __("Auswählen", context)}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
        {!addon && data.add_on_product ? (
          <div
            className={classNames("addon", {
              marked: data.add_on_product && data.add_on_product.selected
            })}
          >
            <ProductWrapper
              key={data.ean_number + "_" + data.add_on_product.ean_number}
              data={data}
              select={this.props.select}
              addon={true}
              onClick={this.props.onClick}
            />
          </div>
        ) : addon ? (
          <>
            <div className="addoninfo">
              <label>
                <input
                  type="checkbox"
                  checked={data.add_on_product.selected}
                  id={data.add_on_product.ean_number}
                  onChange={() => {
                    this.props.toggleAddonProduct(
                      data.ean_number,
                      data.section,
                      this.props.select
                    );
                    if (this.props.select === "custom" && !data.selected) {
                      this.props.toggleProduct(data.ean_number, data.section);
                      track(
                        "Product",
                        data.selected ? "Unselect" : "Select",
                        data.name,
                        data.selected ? -1 : 1
                      );
                    }
                    setTimeout(() => this.props.onClick(), 100);
                    track(
                      "Addon Product",
                      data.add_on_product.selected ? "Unselect" : "Select",
                      data.add_on_product.name,
                      data.add_on_product.selected ? -1 : 1
                    );
                  }}
                />
                <Icon
                  name={
                    data.add_on_product.selected ? "check-square-r" : "square-r"
                  }
                  width="16px"
                  height="16px"
                  style={{width: "16px", height: "16px"}}
                />
              </label>
              <p
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({modal: true});
                  track("Product", "Show", data.name, 1);
                }}
              >
                <span>{__("Zubehör", context)}</span>
                <Icon
                  name="chevron-right"
                  width="30px"
                  height="30px"
                  style={{width: "16px", height: "16px"}}
                />
              </p>
            </div>
          </>
        ) : (
          ""
        )}
        {this.state.modal ? (
          <Modal
            background
            onClose={() => {
              this.setState({modal: false});
              track("Product", "Hide", modVals.name, -1);
              this.component.current
                .closest(".Wide")
                .querySelector(".NavBar").style.zIndex = 2;
            }}
          >
            <div className="header">
              <div className="gallery">
                <div className="image">
                  <Swiper params={params} paramsMobile={params}>
                    {modVals.images.map((d, i) => {
                      return (
                        <div
                          key={modVals.ean_number + "_images_" + i}
                          className={classNames("item", "swiper-slide", {
                            active: i === this.state.image
                          })}
                          onClick={() => {
                            this.setState({image: i});
                          }}
                        >
                          <img src={d} alt="" />
                        </div>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
              <div className="productInfo">
                <p className="name">{__(modVals.name, context)}</p>
                <table className="priceBox" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td>{__("Preis:", context)}</td>
                      <td className="price">
                        {new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        }).format(modVals.price)}
                      </td>
                    </tr>
                    <tr>
                      <td>{__("Bewertung:", context)}</td>
                      <td className="rating">{starRenderer}</td>
                    </tr>
                  </tbody>
                </table>
                <button
                  onClick={() => {
                    track("Button", "Product Buy", modVals.name, -1);
                    window.open(modVals.affiliate_link, "_blank");
                  }}
                >
                  {__("Zum Shop", context)}
                </button>
              </div>
            </div>
            <div className="body">
              {modVals.sensors.length > 0 && (
                <Accordion>
                  <AccordionHeader>Disclaimer</AccordionHeader>
                  <div>{__(modVals.sensors, context)}</div>
                </Accordion>
              )}
              <Accordion>
                <AccordionHeader>Beschreibung</AccordionHeader>
                <div>{__(modVals.description, context)}</div>
              </Accordion>
              <Accordion>
                <AccordionHeader>Inbox Details</AccordionHeader>
                <div>{__(modVals.in_box_details, context)}</div>
              </Accordion>
              <Accordion>
                <AccordionHeader>Technische Details</AccordionHeader>
                <div>
                  <table className="detailsBox" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td>{__("Dimmension:", context)}</td>
                        <td>{__(modVals.dimension, context)}</td>
                      </tr>
                      <tr>
                        <td>{__("EAN Nummer:", context)}</td>
                        <td>{__(modVals.ean_number, context)}</td>
                      </tr>
                      <tr>
                        <td>{__("Farben:", context)}</td>
                        <td>
                          <div className="colors">
                            {modVals.colors &&
                              modVals.colors.map((d, i) => {
                                return (
                                  <span key={modVals.ean_number + "_" + i}>
                                    {getColorHtml(d.color_code)}
                                  </span>
                                );
                              })}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>{__("Verfügbare Menge:", context)}</td>
                        <td>{modVals.quantity}</td>
                      </tr>
                      <tr>
                        <td>{__("Preis:", context)}</td>
                        <td>
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          }).format(modVals.price)}
                        </td>
                      </tr>
                      <tr>
                        <td>{__("Bewertung:", context)}</td>
                        <td className="rating">{starRenderer}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Accordion>
            </div>
          </Modal>
        ) : (
          ""
        )}
        {badge ? (
          <Badge>
            {badge === "best_price" ? (
              <Icon name="euro-sign" />
            ) : badge === "best_rating" ? (
              <Icon name="star" />
            ) : (
              <Icon name="thumbs-up" />
            )}
          </Badge>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    buy: reduxState.settings.settings.buy,
    context: reduxState.multilingual.translations
  };
}
const ProductWrapper = connect(mapStateToProps, {
  toggleProduct,
  toggleAddonProduct
})(Product);
export default ProductWrapper;
