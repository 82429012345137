import { PRODUCTS_PATH } from '../../config/api';
import sendRequest from '../../helpers/api';
import { TOGGLE_PRODUCT, TOGGLE_ADDON_PRODUCT, SET_REQUEST, UPDATE_REQUEST_PRODUCT, SET_PACKS,SET_DOWNLOAD,SET_SHOP,SET_ATTR,SET_BUY,SET_CONRAD,SET_CONRAD_FORM } from './types';
import { validateOptionsQuantity,addOption } from '../question/actions';
import { initConnectionError,setLoader,setPage } from '../navigation/actions';

export function setRequest(data) {
  return {
    type: SET_REQUEST,
    data
  }
}
export function setDownload(value) {
  return {
    type: SET_DOWNLOAD,
    value
  }
}

export function setBuy(value) {
  return {
    type: SET_BUY,
    value
  }
}

export function setShop(value) {
  return {
    type: SET_SHOP,
    value
  }
}
export function setConrad(value) {
  return {
    type: SET_CONRAD,
    value
  }
}
export function setConradForm(value) {
  return {
    type: SET_CONRAD_FORM,
    value
  }
}

export function requestRemoveProduct(slug, question, parent) {
  return (dispatch, getState) => {
    dispatch(validateOptionsQuantity(slug,question,parent));
  }
}

export function requestUpdateProduct(slug, quantity, section) {
  if(section==='Sprachassistenten'){
    section='smart_assistants';
  }
  var s = slug.split('_');
  if(s.length>1){
    s = s[1];
  } else {
    s = s[s.length-1];
  }
  return {
    type: UPDATE_REQUEST_PRODUCT,
    slug:s, quantity, section
  }
}

export function toggleProduct(ean,section) {
  return {
    type: TOGGLE_PRODUCT,
    ean, section
  }
}
export function toggleAddonProduct(ean,section,pack) {
  return {
    type: TOGGLE_ADDON_PRODUCT,
    ean, section,pack
  }
}

export function requestProducts() {
  return (dispatch, getState) => {
    dispatch(setLoader(true));
    return new Promise((resolve, reject) => {
      return sendRequest('PUT', PRODUCTS_PATH+getState().product.shop,getState().product.request,{headers: {"Content-Type": "application/json","Accept":"application/json, text/plain"}})
        .then(
          res => {
            let result = {...res};
            let sma;
              sma = result['smart_assistants'];
              delete result['smart_assistants'];
              delete result['smart_assistants_buy_all'];
            if(sma){
              Object.keys(result).forEach((d,i)=>{
                result[d].smart_assistants = sma;
              });
            }
            dispatch({type:SET_PACKS,data:result});
            dispatch(setPage('shop'))
            dispatch(initConnectionError(''));
            dispatch(setLoader(false));
            resolve();
          }
        )
        .catch(err => {
          dispatch(initConnectionError('requestProducts'));
          dispatch(setLoader(false));
          reject();
        });
    });
  };
}

export function setAttr(attr,value) {
  return (dispatch, getState) => {
    if(attr==='smart_assistant_required'&&getState().product.request.product_answers.os.name==='Apple (iOS)'&&getState().product.shop==='15634362013'){
      let q = getState().question.questions.find(question => question.slug === '15343655265');
      dispatch(addOption(q.slug,q.data.find(option=>option.name==='Siri')));
    }
    return dispatch( {
      type: SET_ATTR,
      attr, value
    });
  }
}
