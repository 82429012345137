import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/scss/core/Switch.scss';

class Switch extends Component {
  state = {
    checked: this.props.value,
  }
  onChange = (e) => {
    this.setState({checked:e.target.checked},()=>{
      if (this.props.onChange) {
        this.props.onChange(this.state.checked);
      }
    });
  }
  render(){
    return <label className='Switch'>
        <input type='checkbox' disabled={this.props.disabled} onChange={this.onChange} checked={this.state.checked}/>
        <span className='dot'/>
    </label>
  }
}
export default Switch;
