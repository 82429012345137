import React, {Component} from "react";
import classNames from "classnames";
import {connect} from "react-redux";
import "../../assets/scss/template-question/accordion.scss";
import QuestionOption from "../QuestionOption";
import Swiper from "../core/Swiper";
import Icon from "../core/Icon";
import {track} from "../../helpers/ga";
import {__} from "../../helpers/multilingual";

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trigger: -1
    };
    this.option = React.createRef();
  }
  triggerThumb = i => {
    this.setState({trigger: i});
    var that = this;
    setTimeout(() => {
      if (that.option.current) {
        const rect = that.option.current
          .querySelector(".optionBox")
          .getBoundingClientRect();
        const parent = that.option.current.closest(".Scrollable");
        const inViewport =
          rect.top + rect.height <= parent.clientHeight + parent.offsetTop;
        if (!inViewport) {
          parent.scrollTo(0, parent.scrollTop + rect.height);
        }
      }
    }, 100);
  };
  render() {
    const {context} = this.props;
    const {type, zoom} = this.props.settings;
    var marked = -1;
    var thumb = this.props.options.map((d, i) => {
      let checked = 0;
      d.variations.forEach((e, j) => {
        checked += e.selected ? 1 : 0;
        marked = e.selected ? j : marked;
      });
      return (
        <div
          key={d.slug}
          className={classNames("thumb", {
            selected: i === this.state.trigger,
            marked: checked > 0,
            round: type === "round"
          })}
          onClick={() => {
            this.triggerThumb(i);
            track("Question Option", "Trigger", d.name, 1);
          }}
        >
          <div className="img">
            <img src={d.images[0]} alt="" />
            {type === "round" && checked > 0 ? (
              <div className="checkCircle">
                <Icon name="check-circle" width="80px" height="80px" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="title">
            <span className="name">
              {__(d.name, context)}
              {checked > 0 ? (
                <span className="checkIcon">
                  <Icon name="check" width="12px" height="12px" />
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      );
    });
    const paramsMobile = {
      slidesPerView: 2,
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      keyboard: {
        enabled: true
      }
    };
    const params = {
      slidesPerView: 3,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      keyboard: {
        enabled: true
      }
    };
    var options = this.props.options
      .filter((d, i) => {
        if (i === this.state.trigger) {
          return true;
        } else if (
          i !== this.state.trigger &&
          i === marked &&
          this.state.trigger === -1
        ) {
          return true;
        }
        return false;
      })
      .map((d, i) => {
        let style = {};
        if (d.slug === "153436552717") {
          style.background = "linear-gradient(90deg,#fdcc76,#f9efe6)";
        }
        if (d.slug === "153436552718") {
          style.background = "linear-gradient(90deg,#de60f8,#efb4fa)";
        }
        return (
          <div key={d.slug} className="optionBox" style={style}>
            <Swiper params={params} paramsMobile={paramsMobile}>
              {d.variations.map((e, j) => {
                return (
                  <QuestionOption
                    key={e.slug}
                    data={e}
                    mode="accordion"
                    trigger={d.slug}
                    className={classNames("swiper-slide", {zoom: zoom})}
                  />
                );
              })}
            </Swiper>
          </div>
        );
      });
    return (
      <div className="QuestionTemplate-accordion" ref={this.option}>
        <div className="accordion">{thumb}</div>
        {options}
      </div>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    start: reduxState.navigation.start,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {})(Accordion);
