import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames'
import Swiper  from 'react-id-swiper';
// import 'react-id-swiper/lib/styles/scss/swiper.scss';
import 'swiper/swiper.scss';
import '../../assets/scss/core/Swiper.scss';
import MediaQuery from 'react-responsive';

class Swipers extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.resize, false);
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize, false);
  }
  resize = () => {
    this.forceUpdate();
  }
  render() {
    const { params, paramsMobile } = this.props;
    return <>
      <MediaQuery maxDeviceWidth={767}>
        <div className={classNames('Swiper',{center:paramsMobile.slidesPerView>=this.props.children.length})}>
          <Swiper {...paramsMobile}>
            {this.props.children}
          </Swiper>
        </div>
      </MediaQuery>
      <MediaQuery minDeviceWidth={768}>
        <div className={classNames('Swiper',{center:params.slidesPerView>=this.props.children.length})}>
          <Swiper {...params}>
            {this.props.children}
          </Swiper>
        </div>
      </MediaQuery>
    </>;
  }
}
function mapStateToProps(reduxState){
  return {
  }
}
export default connect(mapStateToProps,{})(Swipers);
