import React, {Component} from "react";
import {connect} from "react-redux";
import "../assets/scss/NavBar.scss";
import Button from "./core/Button";
import {
  getCurrentQuestion,
  resetQuestion,
  validateOptions
} from "../store/question/actions";
import {
  moveHistory,
  resetNavigation,
  setPage,
  updateHistory
} from "../store/navigation/actions";
import {
  requestProducts,
  setDownload,
  setConradForm
} from "../store/product/actions";
import {track} from "../helpers/ga";
import {__} from "../helpers/multilingual";
import classNames from "classnames";
import Tooltip from "./core/Tooltip";
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.component = React.createRef();
    this.state = {
      scrollPosition: 0,
      scrollDirection: false,
      show: false
    };
  }
  track = label => {
    track("Button", "Click", label, 1);
  };
  resize = e => {
    if (this.component.current) {
      if (this.component.current.children.length === 0) {
        document.documentElement.style.setProperty("--navbar-height", `0px`);
      } else {
        document.documentElement.style.setProperty(
          "--navbar-height",
          `${this.component.current.scrollHeight}px`
        );
      }
    }
  };
  scroll = e => {
    var s =
      e.target.scrollTop ||
      (e.target.documentElement && e.target.documentElement.scrollTop);
    var sh =
      e.target.scrollHeight ||
      (e.target.documentElement && e.target.documentElement.scrollHeight);
    var ch =
      e.target.clientHeight ||
      (e.target.documentElement && e.target.documentElement.clientHeight);
    this.setState(
      {
        scrollPosition: s + ch > sh ? sh - ch : s,
        scrollDirection: this.state.scrollPosition > s
      },
      () => {
        if (
          !this.state.scrollDirection ||
          this.state.scrollPosition <= 0 ||
          (document.documentElement.style.getPropertyValue(
            "--navbar-height"
          ) !== "0px" &&
            this.state.scrollPosition + ch >=
              sh - this.component.current.scrollHeight)
        ) {
          document.documentElement.style.setProperty(
            "--navbar-height",
            `${this.component.current.scrollHeight}px`
          );
        } else {
          document.documentElement.style.setProperty("--navbar-height", `0px`);
        }
      }
    );
  };
  componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize);
    if (
      this.component.current &&
      this.component.current.parentNode.querySelector(".Scrollable")
    ) {
      this.component.current.parentNode
        .querySelector(".Scrollable")
        .addEventListener("scroll", this.scroll);
    } else {
      window.addEventListener("scroll", this.scroll);
    }
  }
  componentDidUpdate(old) {
    if (this.state.show !== this.component.current.children.length > 0) {
      this.setState({show: this.component.current.children.length > 0}, () => {
        this.resize();
      });
    }
    if (old.current !== this.props.current) {
      if (
        this.component.current &&
        this.component.current.parentNode.querySelector(".Scrollable")
      ) {
        this.component.current.parentNode
          .querySelector(".Scrollable")
          .addEventListener("scroll", this.scroll);
      } else {
        window.addEventListener("scroll", this.scroll);
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    if (
      this.component.current &&
      this.component.current.parentNode.querySelector(".Scrollable")
    ) {
      this.component.current.parentNode
        .querySelector(".Scrollable")
        .removeEventListener("scroll", this.scroll);
    } else {
      window.removeEventListener("scroll", this.scroll);
    }
  }
  render() {
    const {first, current, question, page, history, context} = this.props;
    if (current) {
      const category = question.find(q => q.slug === current).category;
      var blockBack = false;
      var skipable = !!question
        .find(q => q.category)
        .options.find(q => q.slug === current);
      if (history.length > 0) {
        blockBack = history[0].slug === current;
      }
      var next = this.props.validateOptions();
      const buttons = (
        <>
          {page === "question" && ((next && !first) || !category) ? (
            <Button
              mode="secondary"
              icon="undo"
              iconMode={3}
              onClick={() => {
                this.props.resetQuestion();
                this.props.resetNavigation();
                this.track("Question - Reset");
              }}
            >
              {__("Neustarten", context)}
            </Button>
          ) : (
            ""
          )}
          {page === "question" &&
          !blockBack &&
          ((next && !first && !category) || !category) ? (
            <Button
              mode="primary"
              icon="arrow-left"
              iconMode={2}
              onClick={() => {
                this.props.moveHistory(-1);
                this.track("Question - Back");
              }}
            >
              {__("Zurück", context)}
            </Button>
          ) : (
            ""
          )}
          {page === "question" && next && !(category && !first) ? (
            <Button
              mode="primary"
              icon="arrow-right"
              iconMode={2}
              onClick={() => {
                this.props.moveHistory(1);
                this.track("Question - Next");
              }}
            >
              {__("Weiter", context)}
            </Button>
          ) : (
            ""
          )}
          {page === "question" &&
          !blockBack &&
          skipable &&
          !next &&
          !category ? (
            <Button
              mode="primary"
              icon="forward"
              iconMode={2}
              onClick={() => {
                this.props.moveHistory(2);
                this.track("Question - Skip");
              }}
            >
              {__("Überspringen", context)}
            </Button>
          ) : (
            ""
          )}
          {page === "question" && category && !first ? (
            <Button
              mode="primary"
              icon="check"
              iconMode={2}
              onClick={() => {
                var that = this;
                this.props.updateHistory(
                  "0-81a2f4ae-b210-44fe-9050-018dc36a4318"
                );
                this.props.moveHistory(1);
                this.props.setPage("conrad");
              }}
            >
              {__("Fertig", context)}
            </Button>
          ) : (
            ""
          )}
          {page === "conrad" && (
            <Button
              mode="primary"
              icon="forward"
              iconMode={2}
              onClick={() => {
                var that = this;
                this.props.setPage("loader");
                window.setTimeout(function() {
                  that.props.setPage("quantity");
                }, 3100);
                this.track("Conrad - Skip");
              }}
            >
              {__("Überspringen", context)}
            </Button>
          )}
          {page === "conrad" && next && !(category && !first) ? (
            <Button
              mode="primary"
              icon="check"
              iconMode={2}
              onClick={() => {
                var that = this;
                this.props.setPage("loader");
                window.setTimeout(function() {
                  that.props.setPage("quantity");
                }, 3100);
                this.track("Conrad - Select");
              }}
            >
              {__("Weiter", context)}
            </Button>
          ) : (
            ""
          )}
          {page === "quantity" ? (
            <Button
              mode="secondary"
              icon="undo"
              iconMode={3}
              onClick={() => {
                this.props.resetQuestion();
                this.props.resetNavigation();
                this.track("Quantity - Reset");
              }}
            >
              {__("Neustarten", context)}
            </Button>
          ) : (
            ""
          )}
          {page === "quantity" && ((next && !first) || !category) ? (
            <Button
              mode="primary"
              icon="check"
              iconMode={2}
              onClick={() => {
                setPage("shop");
                this.props.requestProducts();
                this.track("Quantity - Finish");
              }}
            >
              {__("Fertig", context)}
            </Button>
          ) : (
            ""
          )}
          {page === "shop" ? (
            <Button
              mode="secondary"
              icon="undo"
              iconMode={3}
              onClick={() => {
                this.props.resetQuestion();
                this.props.resetNavigation();
                this.track("Shop - Reset");
              }}
            >
              {__("Neustarten", context)}
              <Tooltip>{__("Konfiguration zurücksetzen", context)}</Tooltip>
            </Button>
          ) : (
            ""
          )}
          {page === "shop" ? (
            <Button
              mode="primary"
              icon="arrow-left"
              iconMode={2}
              onClick={() => {
                this.props.setPage("quantity");
                this.track("Shop - Back");
              }}
            >
              {__("Zurück", context)}
              <Tooltip>{__("Zurück zur Quantitätsauswahl", context)}</Tooltip>
            </Button>
          ) : (
            ""
          )}
          {page === "shop" && this.props.web ? (
            <>
              <Button
                mode="primary"
                iconMode={3}
                icon="globe"
                onClick={() => {
                  this.track("Shop - Web");
                  window.open(this.props.web, "_blank");
                }}
              >
                {this.props.buy === "homeandsmart-konfigurator-21" ? (
                  <span>
                    <span className="mobile">{__("H&S", context)}</span>
                    <span className="desktop">{__("Home&Smart", context)}</span>
                    <Tooltip>{__("Zur H&S-Website gehen", context)}</Tooltip>
                  </span>
                ) : (
                  <>
                    {__("u&i", context)}
                    <span className="desktop"> {__("smarthome", context)}</span>
                    <Tooltip>{__("Zur U&I-Website gehen", context)}</Tooltip>
                  </>
                )}
              </Button>
            </>
          ) : (
            ""
          )}
          {page === "shop" && this.props.download ? (
            <>
              <Button
                mode="primary"
                iconMode={2}
                icon="download"
                onClick={() => {
                  this.props.setDownload(true);
                  this.track("Shop - Download");
                }}
              >
                {__("Download", context)}
                <Tooltip>{__("Produktliste herunterladen", context)}</Tooltip>
              </Button>
            </>
          ) : (
            ""
          )}
          {page === "shop" && this.props.buy && !this.props.conrad ? (
            <>
              <Button
                mode="primary"
                className="amazon"
                iconMode={3}
                icon="amazon"
                onClick={() => {
                  this.track("Shop - Buy");
                  window.open(this.props.buyLink, "_blank");
                }}
              >
                <span className="desktop">{__("Zum", context)} </span>
                {__("Warenkorb", context)}
                <Tooltip>{__("Zur Amazon-Website gehen", context)}</Tooltip>
              </Button>
            </>
          ) : (
            ""
          )}
          {page === "shop" && this.props.buy && this.props.conrad ? (
            <>
              <Button
                mode="primary"
                className="amazon"
                iconMode={3}
                icon="shopping-cart"
                onClick={() => {
                  this.track("Shop - Buy Modal");
                  this.props.setConradForm(true);
                }}
              >
                <span className="desktop">{__("Zum", context)} </span>
                {__("Warenkorb", context)}
                <Tooltip>{__("Zur Partner-Website gehen", context)}</Tooltip>
              </Button>
            </>
          ) : (
            ""
          )}
        </>
      );
      var hack =
        buttons.props.children.filter((d, i) => {
          return d !== "";
        }).length !== 0;
      return (
        <>
          <div className={classNames("NavBar")} ref={this.component}>
            {hack && <p className="hack" />}
            {buttons}
          </div>
        </>
      );
    }
    return <></>;
  }
}
function mapStateToProps(reduxState) {
  return {
    history: reduxState.navigation.history,
    current: reduxState.navigation.current,
    first: reduxState.navigation.first,
    question: reduxState.question.questions,
    page: reduxState.navigation.page,
    download: reduxState.settings.settings.download,
    web: reduxState.settings.settings.web,
    buy: reduxState.settings.settings.buy,
    buyLink: reduxState.product.buy,
    conrad: reduxState.product.conrad,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {
  updateHistory,
  getCurrentQuestion,
  moveHistory,
  resetNavigation,
  resetQuestion,
  validateOptions,
  setPage,
  requestProducts,
  setDownload,
  setConradForm
})(NavBar);
