import {SETTINGS_PATH} from "../../config/api";
import sendRequest from "../../helpers/api";
import {INIT_STYLES, INIT_BRAND, INIT_SETTINGS} from "./types";
import {setShop} from "../product/actions";
import {requestTranslations} from "../multilingual/actions";

export function requestSettings() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return sendRequest("GET", SETTINGS_PATH)
        .then(res => {
          dispatch({type: INIT_STYLES, styles: res.styles});
          dispatch({type: INIT_BRAND, brand: res.brand});
          dispatch({type: INIT_SETTINGS, settings: res.settings});
          dispatch(setShop(res.settings.shop));
          dispatch(
            requestTranslations(
              res.settings.multilingual && res.settings.multilingual.id
                ? res.settings.multilingual.id
                : res.settings.shop
            )
          );

          resolve();
        })
        .catch(err => {
          reject();
        });
    });
  };
}
