import React, {Component} from "react";
import {connect} from "react-redux";
import Icon from "./core/Icon";
import Tooltip from "./core/Tooltip";
import "../assets/scss/ToolBar.scss";
import {getCurrentQuestion} from "../store/question/actions";
import classNames from "classnames";
import {__} from "../helpers/multilingual";

class ToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: [
        {
          mode: 0,
          color: "rgb(0, 128, 0)"
        },
        {
          mode: 0,
          color: "rgb(128, 36, 0)"
        },
        {
          mode: 0,
          color: "rgb(150, 183, 210)"
        },
        {
          mode: 0,
          color: "rgb(255, 165, 0)"
        },
        {
          mode: 0,
          color: "rgb(181, 1, 227)"
        },
        {
          mode: 0,
          color: "rgb(230, 105, 105)"
        }
      ]
    };
    this.component = React.createRef();
  }
  componentDidMount() {
    document.documentElement.style.setProperty(
      "--toolbar-height",
      `${this.component.current.clientHeight}px`
    );
  }
  componentDidUpdate() {
    document.documentElement.style.setProperty(
      "--toolbar-height",
      `${this.component.current.clientHeight}px`
    );
    const cur = this.props.questions.find(
      question => question.slug === this.props.current
    );
    let item = {...this.state.item};
    let index =
      cur.question === "Beleuchtung" ||
      cur.slug === "153436552715" ||
      cur.slug === "153436552737"
        ? 0
        : cur.question === "Heizung"
        ? 1
        : cur.question === "Sicherheit"
        ? 2
        : cur.question === "Mehr Geräte"
        ? 3
        : cur.question === "Entertainment"
        ? 4
        : cur.question === "Smart Lautsprecher"
        ? 5
        : -1;
    let colorable =
      cur.template.name === "accordion"
        ? cur.options.filter(
            e => e.variations.filter(d => d.selected).length > 0
          ).length > 0
        : cur.options.filter(d => d.selected).length > 0;
    if (item && index !== -1 && item[index].mode !== 0 && !colorable) {
      item[index].mode = 0;
      this.setState({item: item});
    } else if (item && index !== -1 && item[index].mode === 0 && colorable) {
      item[index].mode = 1;
      this.setState({item: item});
    }
  }
  render() {
    const {context} = this.props;
    const cur = this.props.questions.find(
      question => question.slug === this.props.current
    );
    const cat = this.props.questions.find(question => question.category);
    return (
      <ul className="ToolBar" ref={this.component}>
        <li className={classNames({active: cur && cur.slug === cat.slug})}>
          <Icon name="home" />
          <Tooltip>{__("Startseite", context)}</Tooltip>
        </li>
        <li
          className={classNames({
            active:
              cur &&
              (cur.question === "Beleuchtung" ||
                cur.slug === "153436552715" ||
                cur.slug === "153436552737")
          })}
        >
          <Icon
            name="bolt"
            style={
              this.state.item[0].mode === 1 &&
              cur.question !== "Beleuchtung" &&
              cur.slug !== "153436552715" &&
              cur.slug !== "153436552737"
                ? {
                    color: document.documentElement.style.getPropertyValue(
                      "--icon"
                    ),
                    opacity: 1,
                    zIndex: 10,
                    animation:
                      "2000ms cubic-bezier(0.39, 0.58, 0.57, 1) 0s 1 normal none running glow"
                  }
                : {}
            }
          />
          <Tooltip>{__("Beleuchtung", context)}</Tooltip>
        </li>
        <li className={classNames({active: cur && cur.question === "Heizung"})}>
          <Icon
            name="thermometer-half"
            style={
              this.state.item[1].mode === 1 && cur.question !== "Heizung"
                ? {
                    color: document.documentElement.style.getPropertyValue(
                      "--icon"
                    ),
                    opacity: 1,
                    zIndex: 10,
                    animation:
                      "2000ms cubic-bezier(0.39, 0.58, 0.57, 1) 0s 1 normal none running glow"
                  }
                : {}
            }
          />
          <Tooltip>{__("Heizung", context)}</Tooltip>
        </li>
        <li
          className={classNames({active: cur && cur.question === "Sicherheit"})}
        >
          <Icon
            name="lock"
            style={
              this.state.item[2].mode === 1 && cur.question !== "Sicherheit"
                ? {
                    color: document.documentElement.style.getPropertyValue(
                      "--icon"
                    ),
                    opacity: 1,
                    zIndex: 10,
                    animation:
                      "2000ms cubic-bezier(0.39, 0.58, 0.57, 1) 0s 1 normal none running glow"
                  }
                : {}
            }
          />
          <Tooltip>{__("Sicherheit", context)}</Tooltip>
        </li>
        <li
          className={classNames({
            active: cur && cur.question === "Mehr Geräte"
          })}
        >
          <Icon
            name="toggle-on"
            style={
              this.state.item[3].mode === 1 && cur.question !== "Mehr Geräte"
                ? {
                    color: document.documentElement.style.getPropertyValue(
                      "--icon"
                    ),
                    opacity: 1,
                    zIndex: 10,
                    animation:
                      "2000ms cubic-bezier(0.39, 0.58, 0.57, 1) 0s 1 normal none running glow"
                  }
                : {}
            }
          />
          <Tooltip>{__("Mehr Geräte", context)}</Tooltip>
        </li>
        <li
          className={classNames({
            active: cur && cur.question === "Entertainment"
          })}
        >
          <Icon
            name="tv"
            style={
              this.state.item[4].mode === 1 && cur.question !== "Entertainment"
                ? {
                    color: document.documentElement.style.getPropertyValue(
                      "--icon"
                    ),
                    opacity: 1,
                    zIndex: 10,
                    animation:
                      "2000ms cubic-bezier(0.39, 0.58, 0.57, 1) 0s 1 normal none running glow"
                  }
                : {}
            }
          />
          <Tooltip>{__("Entertainment", context)}</Tooltip>
        </li>
        {this.props.questions.find(
          question => question.slug === "153566169488"
        ) ? (
          <li
            className={classNames({
              active: cur && cur.question === "Smart Lautsprecher"
            })}
          >
            <Icon
              name="microchip"
              style={
                this.state.item[5].mode === 1 &&
                cur.question !== "Smart Lautsprecher"
                  ? {
                      color: document.documentElement.style.getPropertyValue(
                        "--icon"
                      ),
                      opacity: 1,
                      zIndex: 10,
                      animation:
                        "2000ms cubic-bezier(0.39, 0.58, 0.57, 1) 0s 1 normal none running glow"
                    }
                  : {}
              }
            />
            <Tooltip>{__("Lautsprecher", context)}</Tooltip>
          </li>
        ) : (
          ""
        )}
        <li>
          <Icon name="package" />
          <Tooltip>{__("Produkte", context)}</Tooltip>
        </li>
      </ul>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    questions: reduxState.question.questions,
    current: reduxState.navigation.current,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {getCurrentQuestion})(ToolBar);
