import {createStore, combineReducers, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import questionReducer from "./question/reducers";
import productReducer from "./product/reducers";
import navigationReducer from "./navigation/reducers";
import settingsReducer from "./settings/reducers";
import multilingualReducer from "./multilingual/reducers";

const rootReducer = combineReducers({
  question: questionReducer,
  product: productReducer,
  navigation: navigationReducer,
  settings: settingsReducer,
  multilingual: multilingualReducer
});

export default function configureStore() {
  return createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );
}
