export const SERVER_URL = "https://api.smartivate.co/";
// export const SERVER_URL = "http://localhost:3001/";
export const QUESTIONS_PATH = SERVER_URL + "api/v1/categories";
export const PRODUCTS_PATH = SERVER_URL + "api/v1/getProducts/";
export const TRANSLATIONS_PATH = SERVER_URL + "api/v1/translations/";
// export const SETTINGS_PATH = "./api/settingsUAndI.json";
export const SETTINGS_PATH = "./api/settingsHAndS.json";
// export const SETTINGS_PATH = "./api/settingsPropster.json";
// export const QUESTIONS_PATH = './api/question.json';
// export const PRODUCTS_PATH = './api/product.json';
// export const SETTINGS_PATH = "./api/settingsMultilingualDemo.json";
