import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../assets/scss/ConnectionError.scss';
import { requestQuestions } from '../store/question/actions';
import Scrollable from './core/Scrollable';
import Button from './core/Button';

class ConnectionError extends Component {
  componentDidUpdate(){
  }
  render(){
    var action= ()=>{};
    if(this.props.connectionError==='requestQuestions'){
      action = this.props.requestQuestions;
    }
    return <div className='ConnectionError'>
      <Scrollable center>
        <h1>Verbindungs Fehler</h1>
        <Button onClick={()=>{this.props.requestQuestions()}}>Erneut Versuchen</Button>
      </Scrollable>
    </div>;
  }
}
function mapStateToProps(reduxState){
  return {
    connectionError: reduxState.navigation.connectionError,
  }
}
export default connect(mapStateToProps,{requestQuestions})(ConnectionError);
