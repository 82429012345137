import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../assets/scss/Header.scss';

class Header extends Component {
  componentDidMount(){

  }
  render(){
    const {brand}= this.props;
    return <header className='Header'>
      <div className='logo'>
        {
          this.props.web?<a href={this.props.web} target='_blank' rel='noopener noreferrer'>
            <img src={brand.logo} alt={brand['logo-alt']}/>
          </a>:
          <img src={brand.logo} alt={brand['logo-alt']}/>
        }
      </div>
      <span className='slogan'>{brand.slogan}</span>
      {
        brand.powered?
          <a href='https://smartivate.de' target='_blank' rel='noopener noreferrer'>
            <span className='powered'>
              <i>Powered by</i> <span>Smartivate</span>
            </span>
          </a>
        :''}
    </header>;
  }
}
function mapStateToProps(reduxState){
  return {
    brand: reduxState.settings.brand,
    web: reduxState.settings.settings.web,
  }
}
export default connect(mapStateToProps,{})(Header);
