import update from 'react-addons-update';
import { INIT_STYLES, INIT_BRAND,INIT_SETTINGS } from './types';

const DEFAULT_STATE = {
  styles:{},brand:{},
  settings: {
    download:false
  }
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case INIT_STYLES:{
      return update(state,{
        styles:{
          $set: action.styles
        }
      })
    }
    case INIT_BRAND:{
      return update(state,{
        brand:{
          $set: action.brand
        }
      })
    }
    case INIT_SETTINGS:{
      return update(state,{
        settings:{
          $set: action.settings
        }
      })
    }
    default:
      return state;
  }
};
