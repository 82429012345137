import { INIT_WELCOME, SET_PAGE, SET_PREV, INIT_CONNECTION_ERROR, SET_LOADER, START_CONFIG, RESET_CONFIG, UPDATE_HISTORY, MOVE_HISTORY, CLEAR_HISTORY,AFTER_FIRST } from './types';
import { validateOptions,initSmartspeaker } from '../question/actions';
export function initWelcome(question, description) {
  return {
    type: INIT_WELCOME, question, description
  }
}
export function setPage(page){
  return {
    type: SET_PAGE, page
  }
}
export function initConnectionError(action){
  return {
    type: INIT_CONNECTION_ERROR,
    action
  }
}
export function setLoader(loader){
  return {
    type: SET_LOADER,
    loader
  }
}
export function startConfig(){
  return (dispatch, getState)=>{
    dispatch(setPage('question'));
    let current = getState().question.questions[0].slug;
    if(!getState().navigation.prev){
      current = getState().question.questions[getState().question.questions.findIndex(item=>item.category)].slug;
    }
    dispatch({
      type: START_CONFIG,
      current: current
    });
  }
}

export function afterFirst(){
  return {
    type: AFTER_FIRST
  }
}

export function moveHistory(dir){
  let scroll = document.querySelectorAll('.Scrollable');
  scroll.forEach((s)=>{
    setTimeout(()=>s.scroll(0,0),100);
  });
  return (dispatch, getState)=>{
    const i = getState().navigation.history.findIndex(item => item.slug===getState().navigation.current);
    if(dir<0){
      dispatch(validateOptions(-1));
    }else if(dir===2) {
      dispatch(validateOptions(-1));
      dir--;
    }
    if(dir>0&&i+dir>=getState().navigation.history.length){
      dispatch(startConfig());
      dispatch(afterFirst());
      dispatch(clearHistory());
    }else
    if(dir<0&&i+dir<0){
      dispatch(startConfig());
      dispatch(clearHistory());
    }else
    if(getState().navigation.current!==''){
      dispatch({
        type: MOVE_HISTORY,
        dir
      })
    }else{
    }
  }
}
export function prevHistory() {
  return (dispatch, getState)=>{
    const i = getState().navigation.history.findIndex(item => item.slug===getState().navigation.current)-1;
    if(i>=0){
      return getState().navigation.history[i];
    }
    return {slug: getState().question.questions.find(question=>question.category).slug,id:0};
  }
}
export function updateHistory(slug){
  return (dispatch, getState)=>{
    if (slug==='15343655262'||slug==='15343655263') {
      slug ='15343655264';
    }
    if (slug==='0' || slug==='0-07e1a9b2-93b5-4572-99b2-8fca09f9d7bc') {
      slug ='15343655265';
    }
    if (slug ==='0-a1bcf2cb-bf65-4acf-aa2d-32fec7c74914') {
      slug ='15343655266';
    }
    if (slug ==='0-15ba9417-bf80-4aa1-8c96-ed02cd0cc2ed' || slug==='0-edb61ef0-2518-4987-acbd-19e856d8f8a6' || slug=== '0-478af738-ff9c-4648-a8c2-9ec0e2e07fc9' ) {
      slug ='15343655266';
    }
    if (slug==='15343655267'||slug==='15343655268'||slug==='15343655269'){
      slug = '153436552610';
    }
    if(slug==='153436552610'){
      dispatch(initSmartspeaker());
      dispatch(setPrev(false));
    }
    const id = getState().question.questions.findIndex(question=>question.slug===slug);
    if(id>=0){
      dispatch({
        type: UPDATE_HISTORY,
        item: slug,
        id: id
      })
    }
  };
}
export function clearHistory(){
  return{
    type: CLEAR_HISTORY
  }
}
export function resetNavigation(){
  return {
    type: RESET_CONFIG
  }
}
export function setPrev(prev){
  return {
    type: SET_PREV,prev
  }
}
