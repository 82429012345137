export const RESET_CONFIG = 'RESET_CONFIG';
export const TOGGLE_PRODUCT = 'TOGGLE_PRODUCT';
export const TOGGLE_ADDON_PRODUCT = 'TOGGLE_ADDON_PRODUCT';
export const SET_REQUEST = 'SET_REQUEST';
export const UPDATE_REQUEST_PRODUCT = 'UPDATE_REQUEST_PRODUCT';
export const REMOVE_REQUEST_PRODUCT = 'REMOVE_REQUEST_PRODUCT';
export const SET_PACKS = 'SET_PACKS';
export const SET_DOWNLOAD = 'SET_DOWNLOAD';
export const SET_SHOP = 'SET_SHOP';
export const SET_ATTR = 'SET_ATTR';
export const SET_BUY = 'SET_BUY';
export const SET_CONRAD = 'SET_CONRAD';
export const SET_CONRAD_FORM = 'SET_CONRAD_FORM';
