import ReactGA from "react-ga";

export function init(key) {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(key);
  }
}

export function track(category, action, label, value) {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
  if (label === "Shop - Buy - Conrad") {
    window.fbq("track", "InitiateCheckout");
  }
  if (label === "Conrad - Select") {
    window.fbq("track", "AddToCart");
  }
}
