import React, {Component} from "react";
import {connect} from "react-redux";
import {requestQuestions} from "../store/question/actions";
import {requestSettings} from "../store/settings/actions";
import Box from "./template-layout/Box";
import Theme from "./Theme";
import "../assets/scss/Layout.scss";
import Wide from "./template-layout/Wide";
import Div100vh from "react-div-100vh";

class Layout extends Component {
  resize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(
      "--vh",
      `${window.outerHeight}px`
    );
  };
  componentDidMount() {
    this.props.requestQuestions();
    this.props.requestSettings();
    this.resize();
    window.addEventListener("resize", this.resize);
    !!navigator.platform &&
      /iPad|iPhone|iPod/.test(navigator.platform) &&
      document.documentElement.classList.add("iOs");
  }
  componentDidUpdate() {
    document.documentElement.classList.remove("wide");
    (this.props.page === "quantity" || this.props.page === "shop") &&
      document.documentElement.classList.add("wide");
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  render() {
    return (
      <Div100vh style={{minHeight: "100rvh", height: "100rvh"}}>
        <div className="Layout">
          <Theme>
            {this.props.page === "welcome" ||
            this.props.page === "question" ||
            this.props.page === "loader" ||
            this.props.page === "conrad" ? (
              <Box />
            ) : (
              <Wide />
            )}
          </Theme>
        </div>
      </Div100vh>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    page: reduxState.navigation.page,
    analytics: reduxState.settings.settings.analytics
  };
}
export default connect(mapStateToProps, {
  requestQuestions,
  requestSettings
})(Layout);
