import update from 'react-addons-update';
import { RESET_CONFIG, TOGGLE_PRODUCT,TOGGLE_ADDON_PRODUCT,SET_REQUEST, UPDATE_REQUEST_PRODUCT,REMOVE_REQUEST_PRODUCT,SET_PACKS,SET_DOWNLOAD,SET_SHOP,SET_ATTR,SET_BUY,SET_CONRAD,SET_CONRAD_FORM } from './types';

const DEFAULT_STATE = {
  request:{
    product_answers: {
      os: {
        name: "Android",
        smart_assistant_required: false,
        smart_assistant_present: false,
        smart_assistant_name: "Alexa"
      },
      home_type: "independent_house",
      categories:{
      }
    }
  },
  packs: {},
  download: false,
  shop: '',
  conrad: false,
  conrad_form: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case RESET_CONFIG: {
      return DEFAULT_STATE;
    }
    case SET_PACKS : {
      return update(state,{
        packs:{
          $set: action.data
        }
      });
    }
    case SET_SHOP: {
      return update(state,{
        shop:{
          $set: action.value
        }
      });
    }
    case SET_CONRAD: {
      return update(state,{
        conrad:{
          $set: action.value
        }
      });
    }
    case SET_CONRAD_FORM: {
      return update(state,{
        conrad_form:{
          $set: action.value
        }
      });
    }
    case SET_REQUEST: {
      const data = action.data;
      var r={
        "":{
          "":{
        		"products": [
              {
                "category_id": "",
                "quantity": "1"
              }
            ]
        	}
      	}
      };
      for(var i = 0;i<data.length;i++){
        var k= Object.keys(data[i])[0];
        if(data[i][k][Object.keys(data[i][k])[0]].products.length){
          r[k]=data[i][k];
        }
      }
      return update(state,{
        request:{
          product_answers:{
            categories:{
              $set: r
            }
          }
        }
      });
    }
    case REMOVE_REQUEST_PRODUCT : {
      let shop = 'other_products';
      var newState = {...state};
      let id = newState.request.product_answers.categories[action.section.toLowerCase()][shop].products.findIndex(product => product.category_id===action.slug);
      let products =newState.request.product_answers.categories[action.section.toLowerCase()][shop].products.filter((product,index)=>index!==id);
      if(id>=0&&products.length>=0) {
        return update(state,{
          request:{
            product_answers:{
              categories:{
                [action.section.toLowerCase()]:{
                  [shop]:{
                    products:{
                      $set:products
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        delete newState.request.product_answers.categories[action.section.toLowerCase()];
        return update(state,{
          request:{
            product_answers:{
              categories:{
                $set:newState.request.product_answers.categories
              }
            }
          }
        });
      }
    }
    case UPDATE_REQUEST_PRODUCT: {
      let shop = 'other_products';
      let id = state.request.product_answers.categories[action.section.toLowerCase()][shop].products.findIndex(product => product.category_id===action.slug);
      return update(state,{
        request:{
          product_answers:{
            categories:{
              [action.section.toLowerCase()]:{
                [shop]:{
                  products:{
                    [id]:{
                      quantity:{
                        $set: action.quantity
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }
    case TOGGLE_PRODUCT: {
      let id = state.packs.custom[action.section].findIndex(product=>product.ean_number===action.ean);
      var value = !state.packs.custom[action.section][id].selected;
      return (
        update(state,{
          packs:{
            custom:{
              [action.section]:{
                [id]:{
                  selected: {
                     $set: value
                  }
                }
              }
            }
          }
        })
      )
    }
    case TOGGLE_ADDON_PRODUCT: {
      let id = state.packs[action.pack][action.section].findIndex(product=>product.ean_number===action.ean);
      var value = !state.packs[action.pack][action.section][id].add_on_product.selected;
      return (
        update(state,{
          packs:{
            [action.pack]:{
              [action.section]:{
                [id]:{
                  add_on_product: {
                    selected: {
                       $set: value
                    }
                  }
                }
              }
            }
          }
        })
      )
    }
    case SET_DOWNLOAD: {
      return (
        update(state,{
          download:{
            $set: action.value
          }
        })
      )
    }
    case SET_BUY : {
      return (
        update(state,{
          buy:{
            $set: action.value
          }
        })
      )
    }
    case SET_ATTR: {
      return (
        update(state,{
          request:{
            product_answers:{
              os:{
                [action.attr]:{
                  $set: action.value
                }
              }
            }
          }
        })
      )
    }
    default:
      return state;
  }
};
