import { Component } from 'react';
import { connect } from 'react-redux';

class Theme extends Component {
  componentDidUpdate(){
    const { styles } = this.props;
    for( let prop in styles ){
      document.documentElement.style.setProperty( '--'+prop, styles[prop] );
    }
  }
  render(){
    return this.props.children;
  }
}
function mapStateToProps(reduxState){
  return {
    styles: reduxState.settings.styles,
  }
}
export default connect(mapStateToProps,{})(Theme);
