import React, {Component} from "react";
import {connect} from "react-redux";
import Button from "../core/Button";
import Tooltip from "../core/Tooltip";
import "../../assets/scss/forms/BuyForm.scss";
import {track} from "../../helpers/ga";
import {__} from "../../helpers/multilingual";
class BuyForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }
  componentDidMount() {
    this.form.current
      .closest(".Wide")
      .querySelector(".NavBar").style.zIndex = 0;
  }
  componentWillUnmount() {
    this.form.current
      .closest(".Wide")
      .querySelector(".NavBar").style.zIndex = 2;
  }
  track = label => {
    track("Button", "Click", label, 1);
  };
  render() {
    const {context} = this.props;
    return (
      <form ref={this.form} className="BuyForm">
        <h2>{__("Wählen sie eine Aktion aus", context)}</h2>
        <div>
          <div>
            <h3>{__("Alle Produkte in den Warenkorb legen!", context)}</h3>
            <Button
              mode="primary"
              className="amazon"
              iconMode={4}
              icon="amazon"
              onClick={e => {
                e.preventDefault();
                this.track("Shop - Buy");
                window.open(this.props.buyLink, "_blank");
              }}
            >
              {__("Jetzt bestellen!", context)}
              <Tooltip>{__("Zur Amazon-Website gehen", context)}</Tooltip>
            </Button>
          </div>
          <div>
            <h3>{__("Conrad Connect nutzen!", context)}</h3>
            <Button
              mode="primary"
              className="amazon"
              iconMode={4}
              icon="cloud"
              onClick={e => {
                e.preventDefault();
                this.track("Shop - Buy - Conrad");
                window.open(
                  "https://conradconnect.com/?utm_source=smartivate.de&utm_medium=referral6utm_campaign=configurator_affiliate_test_0420",
                  "_blank"
                );
              }}
            >
              {__("Jetzt bestellen!", context)}
              <Tooltip>{__("Zu Conrad Connect", context)}</Tooltip>
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    buyLink: reduxState.product.buy,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {})(BuyForm);
