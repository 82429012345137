import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../../assets/scss/core/Fab.scss';
import Icon from './Icon';

class Fab extends Component {
  render(){
    let style = this.props.style;
    if(!style){
      style = {};
    }
    if(this.props.right) {
      style.right= this.props.right;
    }
    if(this.props.bottom) {
      style.bottom= this.props.bottom;
    }
    return <button className={classNames('Fab', {['mode-'+this.props.iconMode]:this.props.iconMode},this.props.mode,this.props.className)} onClick={this.props.onClick} style={style} ><Icon name={classNames(this.props.icon)} style={this.props.iconStyle}/></button>;
  }
}
function mapStateToProps(reduxState){
  return {
  }
}
export default connect(mapStateToProps,{})(Fab);
