import React, {Component} from "react";
import {connect} from "react-redux";
import "../../assets/scss/template-layout/Box.scss";
import Header from "../Header";
import WelcomeView from "../WelcomeView";
import QuestionView from "../QuestionView";
import NavBar from "../NavBar";
import Loader from "../Loader";
import ConnectionError from "../ConnectionError";
import Modal from "../core/Modal";
import Icon from "../core/Icon";
import ToolBar from "../ToolBar";
import Scrollable from "../core/Scrollable";
import Settings from "../core/Settings";
import GDPR from "../GDPR";
import Div100vh from "react-div-100vh";
import {__} from "../../helpers/multilingual";

class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first: true,
      settings: false
    };
  }
  componentDidUpdate() {
    if (this.props.first && !this.state.first) {
      this.setState({first: true});
    }
  }
  render() {
    const {context} = this.props;
    return (
      <div className="Box">
        <Scrollable scrollButton>
          <div className="sticky">
            <div
              className="settings"
              onClick={() => {
                this.setState({settings: true});
              }}
            >
              <Icon name="cog" width="20px" height="20px" />
              {this.state.settings && (
                <Modal
                  background
                  preventDefault
                  onClose={() => {
                    this.setState({settings: false});
                  }}
                >
                  <Settings />
                </Modal>
              )}
            </div>
            <GDPR />
            <Header />
            {this.props.loader ? (
              <Loader>
                <Icon
                  name="loader"
                  width="100px"
                  height="100px"
                  animation="rotate"
                  gradient
                />
              </Loader>
            ) : this.props.page === "loader" ? (
              <Loader>
                <div className="Loader">
                  <div className="icon-animation">
                    <img src="./icon/alarm.svg" alt="" />
                    <img src="./icon/alexa.svg" alt="" />
                    <img src="./icon/blinds.svg" alt="" />
                    <img src="./icon/smart-home.svg" alt="" />
                    <img src="./icon/remote-control.svg" alt="" />
                    <img src="./icon/google-home.svg" alt="" />
                    <img src="./icon/doorbell.svg" alt="" />
                    <img src="./icon/door-sensor-alarmed.svg" alt="" />
                  </div>
                  <h1>
                    {__("Ihr zukünftiges Smarthome wird konfiguriert", context)}
                  </h1>
                  <h1>
                    {__(
                      "Das Konfigurationsprozess wurde abgeschlossen! Sie können Ihre Auswahl nicht mehr ändern!",
                      context
                    )}
                  </h1>
                </div>
              </Loader>
            ) : this.props.start ? (
              <QuestionView />
            ) : this.props.connectionError ? (
              <ConnectionError />
            ) : (
              <WelcomeView />
            )}
          </div>
        </Scrollable>
        {this.props.loader || this.props.page === "loader" ? (
          ""
        ) : this.props.start && !this.props.prev ? (
          <NavBar />
        ) : (
          ""
        )}
        {this.props.loader || this.props.page === "loader" ? (
          ""
        ) : this.props.start && !this.props.prev ? (
          <ToolBar />
        ) : (
          ""
        )}
        {!this.props.first && this.state.first ? (
          <Modal background onClose={() => this.setState({first: false})}>
            <p>
              <strong>{__("Information", context)}</strong>
            </p>
            <p>
              {__(
                "Sie haben den ersten Konfigurierdurchgang erfolgreich abgeschlossen, nun können Sie Ihre Auswahl bearbeiten, bzw. neue Kategorien konfigurieren.",
                context
              )}
            </p>
            <p>
              <strong>{__("Hinweis:", context)}</strong>{" "}
              {__("Wenn Sie auf", context)}{" "}
              <strong>{__("Fertig", context)}</strong>{" "}
              {__(
                "klicken, wird die Konfiguration Ihres SmartHomes abgeschlossen und Sie können nichts mehr bearbeiten",
                context
              )}
            </p>
            <button onClick={() => this.setState({first: false})}>
              {__("Verstanden!", context)}
            </button>
          </Modal>
        ) : (
          ""
        )}
      </div>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    start: reduxState.navigation.start,
    first: reduxState.navigation.first,
    connectionError: reduxState.navigation.connectionError,
    loader: reduxState.navigation.loader,
    page: reduxState.navigation.page,
    prev: reduxState.navigation.prev,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {})(Box);
