import React, {Component} from "react";
import {connect} from "react-redux";
import "../../assets/scss/template-question/default.scss";
import QuestionOption from "../QuestionOption";
import {getCurrentQuestion} from "../../store/question/actions";
import {__} from "../../helpers/multilingual";

class Default extends Component {
  componentDidMount() {}
  render() {
    const {context} = this.props;
    const cur = this.props.getCurrentQuestion();
    const options = this.props.options.map((d, i) => {
      return <QuestionOption key={d.slug} data={d} validate collapsable />;
    });
    const questionDataSelected = [];
    const questionDataNotSelected = [];
    if (cur.category) {
      for (var i = 0; i < options.length; i++) {
        if (options[i].props.data.selected) {
          questionDataSelected.push(options[i]);
        } else {
          questionDataNotSelected.push(options[i]);
        }
      }
    }
    return (
      <div className="QuestionTemplate-default">
        {cur.category && !this.props.first ? (
          <>
            <div className="description">
              {__("Sie haben bereits folgende Kategorien ausgewählt:", context)}
            </div>
            <div className="optionBox checked">{questionDataSelected}</div>
            {questionDataNotSelected.length > 0 && (
              <>
                <div className="description">
                  {__(
                    "Sie können noch folgende Kategorien auswählen:",
                    context
                  )}
                </div>
                <div className="optionBox">{questionDataNotSelected}</div>
              </>
            )}
          </>
        ) : (
          <div className="optionBox">{options}</div>
        )}
      </div>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    first: reduxState.navigation.first,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {getCurrentQuestion})(Default);
