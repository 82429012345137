import {TRANSLATIONS_PATH} from "../../config/api";
import sendRequest from "../../helpers/api";
import {INIT_TRANSLATIONS} from "./types";
export function requestTranslations(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return sendRequest("GET", TRANSLATIONS_PATH + id)
        .then(res => {
          dispatch({type: INIT_TRANSLATIONS, translations: res});
          resolve();
        })
        .catch(err => {
          reject();
        });
    });
  };
}
