import update from 'react-addons-update';
import { INIT_WELCOME, SET_PAGE, SET_PREV,INIT_CONNECTION_ERROR, SET_LOADER, START_CONFIG, RESET_CONFIG, UPDATE_HISTORY, MOVE_HISTORY, CLEAR_HISTORY, AFTER_FIRST } from './types';

const DEFAULT_STATE = {
  welcome:{}, history:[], current:'', start:false, connectionError:false, loader:true, first: true, page: 'welcome',prev:true
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_PREV:{
      return update(state,{
        prev:{
          $set: action.prev
        }
      });
    }
    case INIT_WELCOME:{
      return {
        ...state,
        welcome: {question: action.question, description: action.description}
      }
    }
    case INIT_CONNECTION_ERROR:{
      return (
        update(state,{
          connectionError:{
            $set: action.action
          }
        })
      )
    }
    case SET_PAGE: {
      return update(state,{
        page:{
          $set: action.page
        }
      });
    }
    case SET_LOADER:{
      return (
        update(state,{
          loader:{
            $set: action.loader
          }
        })
      )
    }
    case START_CONFIG:{
      return (
        update(state,{
          start:{
            $set: true,
          },
          current:{
            $set: action.current,
          }
        })
      )
    }
    case RESET_CONFIG: {
      return DEFAULT_STATE;
    }
    case UPDATE_HISTORY:{
      const i = state.history.findIndex(item => item.slug===action.item);
      if(i>=0){
        return (
          update(state,{
            history:{
              $splice: [[i,1]]
            }
          })
        )
      }else{
        return (
          update(state,{
            history:{
              $push: [{slug:action.item,id:action.id}]
            }
          })
        )
      }
    }
    case MOVE_HISTORY:{
      state.history.sort((i,j)=>{
        return i.id>j.id?1:i.id<j.id?-1:0;
      })
      const i = state.history.findIndex(item => item.slug===state.current);
      return (
        update(state,{
          history:{
            $set: state.history
          },
          current:{
            $set: state.history[i+action.dir].slug
          }
        })
      )
    }
    case CLEAR_HISTORY:{
      return (
        update(state,{
          history:{
            $set: []
          }
        })
      )
    }
    case AFTER_FIRST:{
      return (
        update(state,{
          first:{
            $set: false
          }
        })
      )
    }
    default:
      return state;
  }
};
