import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuantityView from '../QuantityView';
import ShopView from '../ShopView';
import Header from '../Header';
import NavBar from '../NavBar';
import Icon from '../core/Icon';
import Loader from '../Loader';
import ConnectionError from '../ConnectionError';
import '../../assets/scss/template-layout/Wide.scss';
import Modal from '../core/Modal';
import Settings from '../core/Settings';
import Fab from '../core/Fab';
import classNames from 'classnames';

class Wide extends Component {
  component = React.createRef();
  state = {
    settings:false,
    scrollButton: false,
    bottom:'10px',
    top:true,
  }
  button = () => {
    if(!this.state.scrollButton&&document.body.scrollHeight>window.innerHeight){
      this.setState({scrollButton:true});
    } else if(this.state.scrollButton&&document.body.scrollHeight<=window.innerHeight){
      this.setState({scrollButton:false});
    }
  }
  onScroll = (e) => {
    if(window.scrollY===0){
      this.setState({top:true});
    } else {
      this.setState({top:false});
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this.button();
  }
  componentDidUpdate() {
    this.button();
    let acc = [...this.component.current.querySelectorAll('.Accordion')];
    acc.forEach((d,i)=>{
      d.addEventListener('toggle', () => {
        this.button();
      })
    });
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }
  render() {
    let style = {
      width: '20px',
      height: '30px',
      fontSize: '25px',
    };
    let bottom = window.innerHeight+window.scrollY<document.body.clientHeight-70?0:70;
    return <div className='Wide' ref={this.component}>
      <div className='settings' onClick={()=>{
        this.setState({settings:true});
      }}>
        <Icon name='cog' width='20px' height='20px'/>
        {
          this.state.settings&&<Modal background preventDefault onClose={()=>{this.setState({settings:false})}}>
            <Settings/>
          </Modal>
        }
      </div>
      <Header/>
      {
        this.props.loader?
          <Loader><Icon name='loader' width='100px' height='100px' animation='rotate' gradient/></Loader>
        :this.props.connectionError
        ?<ConnectionError/>
        :this.props.page==='quantity'
        ?<QuantityView/>
        :<ShopView/>}
      {this.props.loader?'':<NavBar/>}
      {(this.component.current&&this.state.scrollButton)&&<Fab className={classNames({top:this.state.top})} mode='primary' icon='chevron-up' iconMode={2} bottom={bottom+'px'} right={((window.innerWidth-this.component.current.clientWidth)/2+10)+'px'}
        iconStyle={style} onClick={()=>{
          if(this.state.top){
            window.scrollTo(0,window.innerHeight);
          } else {
            window.scrollTo(0,0);
          }
        }}
      />}
    </div>;
  }
}

function mapStateToProps(reduxState){
  return {
    brand: reduxState.settings.brand,
    page: reduxState.navigation.page,
    connectionError: reduxState.navigation.connectionError,
    loader: reduxState.navigation.loader,
  }
}
export default connect(mapStateToProps,{})(Wide);
