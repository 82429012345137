import React, { Component } from 'react';
import '../../assets/scss/core/Tooltip.scss';

class Tooltip extends Component {
  render() {
    return <div className='Tooltip'>
        {this.props.children}
      </div>
  }
}
export default Tooltip;
