import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/scss/core/Banner.scss';

class Banner extends Component {
  render() {
    return <div className='Banner'>
      {this.props.children}
    </div>;
  }
}
function mapStateToProps(reduxState){
  return {
  }
}
export default connect(mapStateToProps,{})(Banner);
