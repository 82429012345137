import { QUESTIONS_PATH } from '../../config/api';
import sendRequest from '../../helpers/api';
import { ADD_QUESTION, ADD_OPTION, TOGGLE_OPTION, TOGGLE_OPTION_VARIATION, TOGGLE_OPTION_VARIATION_ACCORDION, RESET_CONFIG } from './types';
import { initWelcome,initConnectionError,setLoader,prevHistory } from '../navigation/actions';
import { requestSettings } from '../settings/actions';

function addQuestion(slug,question,description,hint,template='default',options=null, category=false, data=null){
    return {
      type:ADD_QUESTION,slug,question,description,hint,template,options,category,data
    }
}
export function addOption(question,option){
    return {
      type:ADD_OPTION,question,option
    }
}

function initCategory(dispatch, data){
  var options = data.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, description: d.description, selected: false, images: d.images, final: false
    }
  });
  return dispatch(addQuestion(data.slug, data.question, data.description,data.hint, undefined , options,  true ));
}
function initSecurity(dispatch, data){
  var options = data.children.map((d,i)=>{
    var final =  true;
    var slug = d.slug;
    var variations = undefined;
    if(d.children.length>1){
      final= false;
      variations = d.children.map((e,j)=>{
        return {slug: e.slug, name: e.name, selected: false, images: e.images, final: true}
      });
    }else{
      slug = d.children[0].slug;
    }
    return {
      slug: slug, name: d.name, selected: false, description: d.description, images: d.children[0].images, variations:variations, final: final
    }
  });
  return dispatch(addQuestion(data.slug, data.name, data.description,data.hint, undefined , options ));
}
function initLighting(dispatch,data){
  var options = data.children.map((d,i)=>{
    var final =  false;
    return {
      slug: d.slug, name: d.name, selected: false, description: d.description, images: d.children[0].images, final: final
    }
  });
  dispatch(addQuestion(data.slug, data.name, data.description,data.hint, undefined , options ));
  var bulb = data.children[0];
  options = bulb.children.map((d,i)=>{
      let variations = d.screw_types.map((e,j)=>{
        let variations = undefined;
        let final = true;
        if(e.wattages.length>0){
          final = false;
          variations = e.wattages.map((f,k)=>{
            return {
              slug: d.slug+'_'+e.slug+'_'+f.slug, name: f.name, selected: k===0?true:false, images: f.images, final: true,
            }
          });
        }
        return {
          slug: d.slug+'_'+e.slug, name: e.name, selected: false, images: e.images, final: final, variations: variations
        }
      });
      return {
        slug: d.slug, name: d.name, selected: false, images: d.images, final: false, variations: variations
      }
    });
  dispatch(addQuestion(bulb.slug, bulb.name, bulb.description,bulb.hint, {name:'accordion',type:'round', zoom:true} , options, false ));
  var designer = data.children[1];
  options = designer.children.map((d,i)=>{
    var final =  true;
    var slug = d.slug;
    var variations = undefined;
    if(d.children.length>1){
      final= false;
      variations = d.children.map((e,j)=>{
        return {slug: e.slug, name: e.name, selected: false, images: e.images, final: true}
      });
    }else{
      slug = d.slug;
    }
    return {
      slug: slug, name: d.name, selected: false, description: d.description, images: d.images, variations:variations, final: final
    }
  });
  return dispatch(addQuestion(designer.slug, designer.name, designer.description,designer.hint, undefined , options ));
}
function initHeating(dispatch, data){
  var options = data.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, description: d.description, selected: false, images: d.images, final: true
    }
  });
  return dispatch(addQuestion(data.slug, data.name, data.description,data.hint, undefined , options ));
}
function initEntertainment(dispatch, data){
  var options = data.children.map((d,i)=>{
    let variations = d.children.map((e,j)=>{
      let variations = undefined;
      let final = true;
      if(e.children.length>1){
        final = false;
        variations = e.children.map((f,k)=>{
          return {
            slug: f.slug, name: f.name, selected: false, images: f.images, final: true,
          }
        });
      }
      return {
        slug: e.slug, name: e.name, selected: false, images: e.images, final: final, variations: variations
      }
    });
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false, variations: variations
    }
  });
  return dispatch(addQuestion(data.slug, data.name, data.description,data.hint, {name:'accordion',type:'rect'} , options ));
}
export function initSmartspeaker (){
  return (dispatch,getState) =>{
    if(getState().product.request.product_answers.os.smart_assistant_required){
      const os = getState().question.questions[0];
      var smartspeaker = os.data.find(os=>os.name===getState().product.request.product_answers.os.name).children[0].children[0].children[0];
      var options = os.data.find(os=>os.name===getState().product.request.product_answers.os.name).children[0].children.find(option=>option.name===getState().product.request.product_answers.os.smart_assistant_name).children[0];
      smartspeaker = getState().question.questions[1].data;
      if(!getState().question.questions.find(question => question.category).options.find(option=>option===smartspeaker)){
        var smOptions;
        if(getState().product.shop==='15635450824'){
          smOptions = options.children.filter(option=> option.slug==='1535624124145'||option.slug==='1535620974137').map((d,i)=>{
            return {
              slug: d.slug, name: d.name, description:d.description, selected: false, images: d.images, final: true
            }
          });
        } else {
          smOptions = options.children.filter(option=>option.slug!=='1535622510141'&&option.slug!=='0-2e35653d-bd58-4ce9-9d23-2afbb28039cc').map((d,i)=>{
            return {
              slug: d.slug, name: d.name, description:d.description, selected: false, images: d.images, final: true
            }
          });
        }
        dispatch(addOption(getState().question.questions.find(question => question.category).slug,smartspeaker));
        dispatch(addQuestion(smartspeaker.slug, smartspeaker.question, smartspeaker.description, smartspeaker.hint, undefined , smOptions, false ));
      }
    }
  }
}
export function hardcodeNH (dispatch,getState, res){
  const welcome = res.find(question => question.key_name === 'product_answers');
  dispatch(initWelcome(welcome.question, welcome.description,'Jetzt Konfigurieren'));
  const os = res.find(question => question.key_name === 'os');
  const sma = res.find(question => question.key_name === 'smart_assistant_options');
  const smat = res.find(question => question.key_name === 'NOT-NEEDED');
  const ht = res.find(question => question.key_name === 'home_type');
  var smaOptions = sma.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false
    }
  });
  var smatOptions = smat.children.filter(option=>option.name!=='Siri').map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false
    }
  });
  var osOptions = os.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false
    }
  });
  var htOptions = ht.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false
    }
  });
  dispatch(addQuestion(os.slug, os.question, os.description, os.hint, undefined , osOptions, false, os.children ));
  dispatch(addQuestion(sma.slug, sma.question, sma.description, sma.hint, undefined , smaOptions ));
  dispatch(addQuestion(smat.slug, smat.question, smat.description, smat.hint, undefined , smatOptions, false, smat.children ));
  dispatch(addQuestion(ht.slug, ht.question, ht.description, ht.hint, undefined , htOptions ));

  let questions = res.find(question => question.key_name === 'categories');

  let heating = questions.children.find(question => question.key_name === 'heating');
  heating.children = [heating.children.find(option=>option.key_name==='radiator_thermostat')];

  let security = questions.children.find(question => question.key_name === 'security');
  security.children = [
    security.children.find(option=>option.key_name==='smart_door_lock'),
    security.children.find(option=>option.key_name==='security_sub_categories'),
    security.children.find(option=>option.key_name==='burglar_alarms')
  ];
  let comfort = questions.children.find(question => question.key_name === 'comfort');
  comfort.children = [
    comfort.children.find(option=>option.key_name==='smart_sockets'),
  ];

  let lighting = questions.children.find(question => question.key_name === 'lighting');
  let bulb = lighting.children[0];
  lighting.slug=bulb.slug;

  let bulbOptions = bulb.children.map((d,i)=>{
    let variations = d.screw_types.map((e,j)=>{
      let variations = undefined;
      let final = true;
      if(e.wattages.length>0){
        final = false;
        variations = e.wattages.map((f,k)=>{
          return {
            slug: d.slug+'_'+e.slug+'_'+f.slug, name: f.name, selected: k===0?true:false, images: f.images, final: true,
          }
        });
      }
      return {
        slug: d.slug+'_'+e.slug, name: e.name, selected: false, images: e.images, final: final, variations: variations
      }
    });
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false, variations: variations
    }
  });

  questions.children = [
    questions.children.find(question => question.key_name === 'lighting'),
    questions.children.find(option=>option.key_name==='heating'),
    questions.children.find(option=>option.key_name==='security'),
    questions.children.find(option=>option.key_name==='comfort')
  ];
  dispatch(initCategory(dispatch, questions));
  dispatch(addQuestion(bulb.slug, bulb.name, bulb.description, bulb.hint, {name:'accordion',type:'round', zoom:true} , bulbOptions, false ));
  dispatch(initHeating(dispatch,heating));
  dispatch(initSecurity(dispatch,security));
  dispatch(initSecurity(dispatch,comfort));

}
export function hardcodeUI (dispatch,getState, res){
  const welcome = res.find(question => question.key_name === 'product_answers');
  dispatch(initWelcome(welcome.question, welcome.description,'Jetzt Konfigurieren'));
  const os = res.find(question => question.key_name === 'os');
  const sma = res.find(question => question.key_name === 'smart_assistant_options');
  const smat = res.find(question => question.key_name === 'NOT-NEEDED');
  const ht = res.find(question => question.key_name === 'home_type');
  var smaOptions = sma.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false
    }
  });
  var smatOptions = smat.children.filter(option=>option.name!=='Siri').map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false
    }
  });
  var osOptions = os.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false
    }
  });
  var htOptions = ht.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false
    }
  });
  dispatch(addQuestion(os.slug, os.question, os.description, os.hint, undefined , osOptions, false, os.children ));
  dispatch(addQuestion(sma.slug, sma.question, sma.description, sma.hint, undefined , smaOptions, false, res.find(question=>question.key_name==='smart_assistants') ));
  dispatch(addQuestion(smat.slug, smat.question, smat.description, smat.hint, undefined , smatOptions, false, smat.children ));
  dispatch(addQuestion(ht.slug, ht.question, ht.description, ht.hint, undefined , htOptions ));
  let questions = res.find(question => question.key_name === 'categories');

  let heating = questions.children.find(question => question.key_name === 'heating');

  let security = questions.children.find(question => question.key_name === 'security');
  security.children = [
    security.children.find(option=>option.key_name==='smart_camera'),
    security.children.find(option=>option.key_name==='smart_door_lock'),
    security.children.find(option=>option.key_name==='smart_door_bells'),
    security.children.find(option=>option.key_name==='security_sub_categories'),
    security.children.find(option=>option.key_name==='security_sub_categories_two'),
    security.children.find(option=>option.key_name==='smoke_detectors')
  ];
  security.children = security.children.filter(d=>{return (typeof d !== 'undefined')});
  let comfort = questions.children.find(question => question.key_name === 'comfort');
  comfort.children = [
    comfort.children.find(option=>option.key_name==='weather_station'),
    comfort.children.find(option=>option.key_name==='Dimmer switch'),
    comfort.children.find(option=>option.key_name==='smart_sockets'),
    comfort.children.find(option=>option.key_name==='Vacuum robot'),
    comfort.children.find(option=>option.key_name==='Rolladen')
  ];
  comfort.children = comfort.children.filter(d=>{return (typeof d !== 'undefined')});
  let lighting = questions.children.find(question => question.key_name === 'lighting');

  let lOptions =  lighting.children.map((d,i)=>{
    return {
      slug: d.slug, name: d.name, selected: false, description: d.description, images: d.images, final: false
    }
  });
  let bulb = lighting.children[0];
  // lighting.slug=bulb.slug;
  if(!bulb.children[1].screw_types[1].wattages.includes(bulb.children[0].screw_types[1].wattages[0])) {
    bulb.children[1].screw_types[1].wattages.push(bulb.children[0].screw_types[1].wattages[0]);
    bulb.children[1].screw_types[1].wattages.sort((a,b)=>{
      return parseFloat(a.name) > parseFloat(b.name);
    });
  }
  if(!bulb.children[1].screw_types[2].wattages.includes(bulb.children[1].screw_types[1].wattages[0])) {
    bulb.children[1].screw_types[2].wattages.push(bulb.children[1].screw_types[1].wattages[0]);
    bulb.children[1].screw_types[2].wattages.sort((a,b)=>{
      return parseFloat(a.name) > parseFloat(b.name);
    });
  }
  if(!bulb.children[1].screw_types[2].wattages.includes(bulb.children[1].screw_types[1].wattages[1])) {
    bulb.children[1].screw_types[2].wattages.push(bulb.children[1].screw_types[1].wattages[1]);
    bulb.children[1].screw_types[2].wattages.sort((a,b)=>{
      return parseFloat(a.name) > parseFloat(b.name);
    });
  }
  if(!bulb.children[1].screw_types[3]){
    bulb.children[1].screw_types.push(bulb.children[2].screw_types[4]);
  }
  if(!bulb.children[2].screw_types[0].wattages.includes(bulb.children[1].screw_types[0].wattages[2])) {
    bulb.children[2].screw_types[0].wattages.push(bulb.children[1].screw_types[0].wattages[2]);
    bulb.children[2].screw_types[0].wattages.sort((a,b)=>{
      return parseFloat(a.name) > parseFloat(b.name);
    });
  }
  if(!bulb.children[2].screw_types[1].wattages.includes(bulb.children[1].screw_types[1].wattages[1])) {
    bulb.children[2].screw_types[1].wattages.push(bulb.children[1].screw_types[1].wattages[1]);
    bulb.children[2].screw_types[1].wattages.sort((a,b)=>{
      return parseFloat(a.name) > parseFloat(b.name);
    });
  }
  let bulbOptions = bulb.children.map((d,i)=>{
    let variations = d.screw_types.filter((e,j)=>{
      if((i===0&&j===2) || (i===2&&j===4)){
        return false;
      }
      return true;
    }).map((e,j)=>{
      let variations = undefined;
      let final = true;
      if(e.wattages.length>0){
        final = false;
        variations = e.wattages.filter((g,l)=>{
          if((i===0 && (j===0||j===2)&& g.slug==='153436552722')||(i===0&&j===1&&g.slug==='153436552729')){
            return true;
          } else if(
            (i===1 && j===0&& (g.slug==='153436552722'||g.slug==='153436552723')) ||
            (i===1 && j===1&& (g.slug==='153436552729'||g.slug==='153436552730')) ||
            (i===1 && j===2&& (g.slug==='153436552733'||g.slug==='153436552729'||g.slug==='153436552730')) ||
            (i===1 && j===3&& g.slug==='153436552729')
          ) {
            return true;
          } else if(
            (i===2 && j===0&& (g.slug==='153436552722'||g.slug==='153436552723'||g.slug==='153436552724'||g.slug==='153436552725')) ||
            (i===2 && j===1&& (g.slug==='153436552731'||g.slug==='153436552730')) ||
            (i===2 && j===2&& g.slug==='153436552730') ||
            (i===2 && j===3&& g.slug==='153436552724')
          ) {
            return true;
          }
          return false;
        }).map((f,k)=>{
          return {
            slug: d.slug+'_'+e.slug+'_'+f.slug, name: f.name, selected: k===0?true:false, images: e.images, final: true,
          }
        });
      }
      return {
        slug: d.slug+'_'+e.slug, name: e.name, selected: false, images: e.images, final: final, variations: variations
      }
    });
    return {
      slug: d.slug, name: d.name, selected: false, images: d.images, final: false, variations: variations
    }
  });
  let designer = lighting.children[1];
  let designerOptions  = designer.children.map((d,i)=>{
      var final =  true;
      var slug = d.slug;
      var variations = undefined;
      if(d.children.length>1){
        final= false;
        variations = d.children.map((e,j)=>{
          return {slug: e.slug, name: e.name, selected: false, images: e.images, final: true}
        });
      }else{
        slug = d.slug;
      }
      return {
        slug: slug, name: d.name, selected: false, description: d.description, images: d.images, variations:variations, final: final
      }
    });
  let entertainment = questions.children.find(question => question.key_name === 'Entertainment');
  entertainment.children = [
    entertainment.children.find(option=>option.name==='Kopfhörer'),
    entertainment.children.find(option=>option.key_name===''),
    entertainment.children.find(option=>option.key_name==='Speakers'),
  ];

  questions.children = [
    questions.children.find(question => question.key_name === 'lighting'),
    questions.children.find(option=>option.key_name==='heating'),
    questions.children.find(option=>option.key_name==='security'),
    questions.children.find(option=>option.key_name==='comfort'),
    questions.children.find(option=>option.key_name==='Entertainment')
  ];
  dispatch(initCategory(dispatch, questions));
  dispatch(addQuestion(lighting.slug, lighting.name, lighting.description, lighting.hint, undefined , lOptions, false ));
  dispatch(addQuestion(bulb.slug, bulb.name, bulb.description, bulb.hint, {name:'accordion',type:'round', zoom:true} , bulbOptions, false ));
  dispatch(addQuestion(designer.slug, designer.name, designer.description, designer.hint, 'undefined' , designerOptions, false ));
  dispatch(initHeating(dispatch,heating));
  dispatch(initSecurity(dispatch,security));
  dispatch(initSecurity(dispatch,comfort));
  dispatch(initEntertainment(dispatch,entertainment));
  const conrad = res.find(question => question.key_name === 'CC Services');
dispatch(addQuestion(conrad.slug, conrad.question, conrad.description, conrad.hint, undefined , conrad.children ));

}
export function getCurrentQuestion(){
  return (dispatch, getState) => {
    return getState().question.questions.find(question=>question.slug===getState().navigation.current);
  }
}
export function requestQuestions() {
  return (dispatch, getState) => {
    dispatch(setLoader(true));
    return new Promise((resolve, reject) => {
      return sendRequest('GET', QUESTIONS_PATH)
        .then(
          res => {
            // hardcodeNH(dispatch,getState,res);
            hardcodeUI(dispatch,getState,res);
            dispatch(initConnectionError(''));
            dispatch(setLoader(false));
            resolve();
          }
        )
        .catch(err => {
          console.log(err);
          dispatch(initConnectionError('requestQuestions'));
          dispatch(setLoader(false));
          reject();
        });
    });
  };
}
export function toggleOption(option, question=null){
  return (dispatch, getState) => {
    if(question===null){
      question = getState().question.questions.find(question=>question.slug===getState().navigation.current);
    }
    dispatch({
      type: TOGGLE_OPTION, question: question, option:option,
    })
  }
}

export function toggleOptionVariation(option, variation, question = null){
  return (dispatch, getState) => {
    if(question===null){
      question = getState().question.questions.find(question=>question.slug===getState().navigation.current);
    }
    dispatch({
      type: TOGGLE_OPTION_VARIATION, question: question, option:option, variation:variation
    })
  }
}
export function toggleOptionVariationAccordion(option, variation, trigger, question=null){
  return (dispatch, getState) => {
    if(question === null){
      question = getState().question.questions.find(question=>question.slug===getState().navigation.current);
    }
    dispatch({
      type: TOGGLE_OPTION_VARIATION_ACCORDION, question: question, option:option, variation:variation, trigger: trigger
    })
  }
}
export function validateOptions(dir,parent){
  return (dispatch, getState) => {
    var cur = dispatch(getCurrentQuestion());
    var selected = 0;
    var success = false;
    var force = false;
    if(cur.template.name!=='accordion'){
      for (var i = 0; i < cur.options.length; i++) {
        let variations = cur.options[i].variations;
        if(cur.options[i].selected&&variations===undefined){
          selected++;
        } else if(cur.options[i].selected&&variations!==undefined) {
          let selectedVariation = 0;
          for (var j = 0; j < variations.length; j++ ){
            if(variations[j].selected&&variations[j].variations===undefined){
              selected++;
              selectedVariation++;
            } else if(variations[j].variations!==undefined){
            }
          }
          if(selectedVariation===0){
            force = true;
            if(dir===-1){
              dispatch(toggleOption(cur.options[i].slug));
            }
          }
        }
      }
    } else {
      for (let i = 0; i < cur.options.length; i++) {
        let variations = cur.options[i].variations;
        for (let j = 0; j < variations.length; j++ ){
          if(variations[j].selected&&variations[j].variations===undefined){
            selected++;
          } else if(variations[j].selected&&variations!==undefined) {
            let selectedVariation = 0;
            let variationsAccordion = variations[j].variations;
            for (var k = 0; k < variationsAccordion.length; k++ ){
              if(variationsAccordion[k].selected&&variationsAccordion[k].variations===undefined){
                selected++;
                selectedVariation++;
              } else if(variationsAccordion[k].variations!==undefined){
              }
            }
            if(selectedVariation===0){
              force = true;
              if(dir===-1){
                dispatch(toggleOption(variations[j].slug));
              }
            }
          }
        }
      }
    }
    if(selected===0||force){
      if(dir===-1){
        var parent = getState().question.questions.find(question=>question.slug===dispatch(prevHistory()).slug);
        if(getState().navigation.first){
          parent = getState().question.questions.find(question=>question.category);
        }
        if(parent.options.findIndex(option=>option.slug===cur.slug)===-1){
          parent = getState().question.questions.find(question=>question.options.find(option=>option.slug===cur.slug));
        }
        dispatch(toggleOption(cur.slug,parent));
      }
      success = false;
    } else {
      success = true;
    }
    return success;
  }
}
export function validateOptionsQuantity(slug,question,parent){
  return (dispatch, getState) => {
    var selected = 0;
    var option;
    if(question.template.name!=='accordion'){
      option = question.options.find(option=>option.slug===slug);
      if(option){
        dispatch(toggleOption(option.slug,question));
        let category = question.options.filter(option=>option.selected);
        if(category.length===1){
          selected++;
        }
      } else{
        question.options.forEach((d,i)=>{
          if(d.variations){
            d.variations.forEach((e,j)=>{
              if(e.slug===slug){
                option = d;
              }
            });
          }
        });
        // option = question.options.find(option=>option.variations.find(variation=>variation.slug===slug));
        dispatch(toggleOptionVariation(option.slug,slug,question));
        let variation = option.variations.filter(variation => variation.selected);
        if(variation.length===1){
          dispatch(toggleOption(option.slug,question));
          let category = question.options.filter(option=>option.selected);
          if(category.length===1){
            selected++;
          }
        }
      }
    } else {
      option = question.options.find(option=>option.variations.find(variation=>variation.slug===slug));
      if(option){
        dispatch(toggleOptionVariation(option.slug,slug,question));
      } else{
        let trigger = question.options.find(option=>option.variations.find(variation=>{
          if(variation.variations){
            return variation.variations.find(variation2=>variation2.slug===slug) ;
          }
          return false;
        }));
        option = trigger.variations.find(variation=>{
          if(variation.variations){
            return variation.variations.find(variation2=>variation2.slug===slug) ;
          }
          return false;
        });
        dispatch(toggleOptionVariationAccordion(option.slug,slug,trigger.slug,question));
        let variation = option.variations.filter(variation => variation.selected);
        if(variation.length===1){
          dispatch(toggleOption(option.slug,question));
        }
      }
      question.options.forEach((d,i)=>{
        d.variations.forEach((e,j)=>{
          if(e.selected){
            selected++;
          }
        });
      });
    }
    if(selected===1){
      let category = getState().question.questions.find(question=>question.category);
      dispatch(toggleOption(parent.slug,category));
    }
  }
}
export function resetQuestion(){
  return (dispatch, getState) => {
    document.documentElement.style.setProperty('--navbar-height', 'min-content');
    document.documentElement.style.setProperty('--toolbar-height', 'min-content');
    dispatch({
      type: RESET_CONFIG
    });
    dispatch(requestQuestions());
    dispatch(requestSettings());
  }
}
