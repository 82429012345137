import React, {Component} from "react";
import {connect} from "react-redux";
import Pdf from "../Pdf";
import Button from "../core/Button";
import {PDFDownloadLink} from "@react-pdf/renderer";
import "../../assets/scss/forms/DownloadForm.scss";
import {__} from "../../helpers/multilingual";

class DownloadForm extends Component {
  constructor(props) {
    super(props);
    this.link = React.createRef();
    this.form = React.createRef();
  }
  state = {
    customerName: "",
    customerAddress: "",
    loading: true
  };
  onChange = (state, value) => {
    this.setState({loading: true}, () => this.setState({[state]: value}));
  };
  componentDidMount() {
    this.form.current
      .closest(".Wide")
      .querySelector(".NavBar").style.zIndex = 0;
  }
  componentWillUnmount() {
    this.form.current
      .closest(".Wide")
      .querySelector(".NavBar").style.zIndex = 2;
  }
  render() {
    const {packs, tab, context} = this.props;
    var products = [];
    for (var props in packs) {
      if (props !== "buy_all") {
        var list = packs[props]
          .filter(product => {
            if (product.add_on_product && product.add_on_product.selected) {
              const addon = {
                ...product.add_on_product,
                quantity: 1,
                ean: product.add_on_product.ean_number
              };
              products.push(addon);
            }
            if (tab === "custom" && product.selected) {
              return true;
            } else if (tab !== "custom") {
              return true;
            }
            return false;
          })
          .map((d, i) => {
            return {
              ean: d.ean_number,
              name: d.name,
              quantity: d.quantity,
              price: d.price
            };
          });
        products = [...products, ...list];
      }
    }
    return (
      <form ref={this.form}>
        <p>
          {__(
            "Hier können Sie die Ergebnisse aus Ihrer durchgeführten Konfiguration mit dem smartivate Smart Home Konfigurator sehen.",
            context
          )}
        </p>
        <label>
          {__("Name:", context)}
          <input
            type="text"
            name="name"
            value={this.state.customerName}
            onChange={e => this.onChange("customerName", e.target.value)}
          />
        </label>
        <label>
          {__("Email-Adresse:", context)}
          <input
            type="email"
            name="address"
            value={this.state.customerAddress}
            onChange={e => this.onChange("customerAddress", e.target.value)}
          />
        </label>
        <a href="#s" ref={this.link} />
        {this.state.loading ? (
          <Button
            onClick={e => {
              e.preventDefault();
              this.setState({loading: false});
            }}
          >
            {__("Download", context)}
          </Button>
        ) : (
          <Button onClick={() => this.link.current.click()}>
            {
              <PDFDownloadLink
                document={
                  <Pdf
                    pdf={this.props.pdf}
                    customer={{
                      name: this.state.customerName,
                      address: this.state.customerAddress.split("\n")
                    }}
                    products={products}
                  />
                }
                fileName={this.state.customerName + ".pdf"}
              >
                {({blob, url, loading, error}) => {
                  if (url) {
                    this.link.current.href = url;
                    this.link.current.download =
                      this.state.customerName + ".pdf";
                    this.link.current.click();
                  }
                  return loading
                    ? __("Dokument wird vorbereitet...", context)
                    : __("Download", context);
                }}
              </PDFDownloadLink>
            }
          </Button>
        )}
      </form>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    pdf: reduxState.settings.settings.download,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {})(DownloadForm);
