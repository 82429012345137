import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../../assets/scss/core/Modal.scss';
import Icon from './Icon';

class Modal extends Component {
  clickOutside = (e) => {
    if(this.props.preventDefault){
      e.stopPropagation();
    }
    if(e.target===e.currentTarget&&this.props.onClose){
      this.props.onClose();
    }
  }
  render(){
    return <div className={classNames('Modal',this.props.className,{background:this.props.background})} onClick={this.clickOutside}>
      <div className='container'>
        {this.props.onClose&&<div className='close' onClick={this.props.onClose}>
          <Icon name='times' width='30px' height='30px' style={{width:'14px',height:'14px'}}/>
        </div>}
        {this.props.children}
      </div>
    </div>;
  }
}
function mapStateToProps(reduxState){
  return {
  }
}
export default connect(mapStateToProps,{})(Modal);
