import React from "react";

const pigLatin = key =>
  key
    .split("")
    .sort(function() {
      return 0.5 - Math.random();
    })
    .join("");

export function __(key, context) {
  /**
  0: default
  1: highlight
  2: pigLatin
  3: highlight + pigLatin
  */
  const translation = (
    context.find(d => d.original.toLowerCase() === key.toLowerCase()) || {
      translation: key
    }
  ).translation;
  const mode =
    parseInt(new URLSearchParams(window.location.search).get("mode")) || 0;
  if (mode > 0) {
    return (
      <span
        {...(mode % 2 === 1 && {
          style: {backgroundColor: key === translation ? "#dc3545" : "#28a745"}
        })}
      >
        {mode > 1 ? pigLatin(translation) : translation}
      </span>
    );
  }
  return translation;
}
