import update from "react-addons-update";
import {INIT_TRANSLATIONS} from "./types";

const DEFAULT_STATE = {
  translations: []
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case INIT_TRANSLATIONS: {
      return update(state, {
        translations: {
          $set: action.translations
        }
      });
    }
    default:
      return state;
  }
};
