import React, {Component, useRef} from "react";
import {connect} from "react-redux";
import "../../assets/scss/core/Accordion.scss";
import {track} from "../../helpers/ga";
import {__} from "../../helpers/multilingual";

export const AccordionHeader = connect(reduxState => {
  return {
    context: reduxState.multilingual.translations
  };
})(({context, children}) => {
  const component = useRef(null);
  return (
    <summary
      className="AccordionHeader"
      ref={component}
      onClick={() =>
        track(
          "Accordion",
          component.current.closest("details").open ? "Close" : "Open",
          children,
          1
        )
      }
    >
      {__(children, context)}
    </summary>
  );
});

class Accordion extends Component {
  render() {
    return (
      <details
        className="Accordion"
        open={this.props.open}
        style={this.props.style}
      >
        {this.props.children}
      </details>
    );
  }
}
function mapStateToProps(reduxState) {
  return {context: reduxState.multilingual.translations};
}
export default connect(mapStateToProps, {})(Accordion);
