export const INIT_WELCOME = 'INIT_WELCOME';
export const START_CONFIG = 'START_CONFIG';
export const RESET_CONFIG = 'RESET_CONFIG';
export const SET_LOADER = 'SET_LOADER';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const MOVE_HISTORY = 'MOVE_HISTORY';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const INIT_CONNECTION_ERROR = 'INIT_CONNECTION_ERROR';
export const AFTER_FIRST = 'AFTER_FIRST';
export const SET_PAGE = 'SET_PAGE';
export const SET_PREV = 'SET_PREV';
