import update from 'react-addons-update';
import { ADD_QUESTION, ADD_OPTION, TOGGLE_OPTION, TOGGLE_OPTION_VARIATION, TOGGLE_OPTION_VARIATION_ACCORDION, RESET_CONFIG } from './types';

const DEFAULT_STATE = {
  questions:[]
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_QUESTION:{
      return {
        ...state,
        questions: [...state.questions, {slug: action.slug, question: action.question, description:action.description,hint:action.hint,template: action.template, options: action.options, category: action.category, data:action.data, selected: false}]
      }
    }
    case ADD_OPTION : {
      let qi = state.questions.findIndex(question => question.slug===action.question);
      return update(state,{
        questions:{
          [qi]:{
            options:{
              $push: [action.option]
            }
          }
        }
      });
    }
    case TOGGLE_OPTION:{
      let qi = state.questions.findIndex(question => question.slug===action.question.slug);
      let options = state.questions[qi].options;
      let oi = options.findIndex(option => option.slug===action.option);

      if( oi ===-1 ){
        for (let i = 0; i < options.length; i++) {
          let h = options[i].variations.findIndex(option => option.slug===action.option);
          if ( h !== -1 ) {
            return update(state,{
              questions:{
                [qi]:{
                  options:{
                    [i]:{
                      variations:{
                        [h]:{
                          selected:{
                            $set: !options[i].variations[h].selected
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        }
      }
      let old = options[oi].selected;
      return update(state,{
        questions:{
          [qi]:{
            options:{
              [oi]:{
                selected:{
                  $set: !old
                }
              }
            }
          }
        }
      });
    }
    case TOGGLE_OPTION_VARIATION:{
      let qi = state.questions.findIndex(question => question===action.question);
      let oi = state.questions[qi].options.findIndex(option => option.slug===action.option);
      let vi = state.questions[qi].options[oi].variations.findIndex(variation => variation.slug===action.variation);
      let old = state.questions[qi].options[oi].variations[vi].selected;
      return update(state,{
        questions:{
          [qi]:{
            options:{
              [oi]:{
                variations:{
                  [vi]:{
                    selected:{
                      $set: !old
                    }
                  }
                }
              }
            }
          }
        }
      });
    }
    case TOGGLE_OPTION_VARIATION_ACCORDION:{
      let qi = state.questions.findIndex(question => question===action.question);
      let ti = state.questions[qi].options.findIndex(option => option.slug===action.trigger);
      let oi = state.questions[qi].options[ti].variations.findIndex(variation => variation.slug===action.option);
      let vi = state.questions[qi].options[ti].variations[oi].variations.findIndex(variation => variation.slug===action.variation);
      let old = state.questions[qi].options[ti].variations[oi].variations[vi].selected;
      return update(state,{
        questions:{
          [qi]:{
            options:{
              [ti]:{
                variations:{
                  [oi]:{
                    variations:{
                      [vi]:{
                        selected:{
                          $set: !old
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }
    case RESET_CONFIG: {
      return DEFAULT_STATE;
    }
    default:
      return state;
  }
};
