import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../../assets/scss/core/Button.scss';
import Icon from './Icon';

class Button extends Component {
  render(){
    return <button className={classNames({['mode-'+this.props.iconMode]:this.props.iconMode},this.props.mode,this.props.className)}onClick={this.props.onClick} style={this.props.style} >{this.props.children}<Icon name={classNames(this.props.icon)} /></button>;
  }
}
function mapStateToProps(reduxState){
  return {
  }
}
export default connect(mapStateToProps,{})(Button);
