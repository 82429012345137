import React, {Component} from "react";
import {connect} from "react-redux";
import "../assets/scss/GDPR.scss";
import Button from "./core/Button";
import Modal from "./core/Modal";
import {withCookies} from "react-cookie";
import {__} from "../helpers/multilingual";
function GDPR({cookies, context}) {
  let cookieDate = new Date();
  cookieDate.setFullYear(cookieDate.getFullYear() + 1);
  var smt = cookies.get("smt");
  return !smt ? (
    <Modal className="GDPR" background preventDefault>
      <h2>{__("Cookie Einstellungen", context)}</h2>
      <div className="description">
        {__(
          "Unser Konfigurator benutzt Cookies um den reibungslosen Ablauf der App zu gewährleisten.",
          context
        )}
      </div>
      <Button
        onClick={() => {
          cookies.set(
            "smt",
            {essentials: {smt: true}, statistics: {ga: true}},
            {path: "/", expires: cookieDate}
          );
        }}
      >
        {__("Alle Cookies aktzeptieren", context)}
      </Button>
      <p
        className="buttonLink"
        onClick={() => {
          cookies.set(
            "smt",
            {essentials: {smt: true}, statistics: {ga: false}},
            {path: "/", expires: cookieDate}
          );
        }}
      >
        {__("Nur die essentiellen Cookies aktzeptieren", context)}
      </p>
      <a
        href={__("https://smartivate.de/datenschutzerklarung", context)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {__("Datenschutz", context)}
      </a>
    </Modal>
  ) : (
    ""
  );
}
function mapStateToProps(reduxState) {
  return {
    welcome: reduxState.navigation.welcome,
    context: reduxState.multilingual.translations
  };
}
export default withCookies(connect(mapStateToProps, {__})(GDPR));
