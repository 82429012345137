import React, {Component} from "react";
import {connect} from "react-redux";
import "../../assets/scss/core/Scrollable.scss";
import classNames from "classnames";
import Fab from "./Fab";

class Scrollable extends Component {
  constructor(props) {
    super(props);
    this.component = React.createRef();
    this.state = {
      scrollButton: false,
      bottom: "10px",
      top: true
    };
  }
  button = () => {
    let that = this;
    const item = this.component.current;
    setTimeout(() => {
      if (!that.state.scrollButton && item.scrollHeight > item.clientHeight) {
        that.setState({scrollButton: true});
      } else if (
        this.state.scrollButton &&
        item.scrollHeight <= item.clientHeight
      ) {
        that.setState({scrollButton: false});
      }
      let bottom =
        parseInt(
          document.documentElement.style.getPropertyValue("--navbar-height")
        ) +
        parseInt(
          document.documentElement.style.getPropertyValue("--toolbar-height")
        ) +
        "px";
      if (bottom !== that.state.bottom) {
        if (Number.isNaN(parseInt(bottom)) || bottom === "0px") {
          bottom = "10px";
        }
        that.setState({bottom: bottom});
      }
    }, 100);
  };
  onScroll = e => {
    if (e.target.scrollTop === 0) {
      this.setState({top: true});
    } else {
      this.setState({top: false});
    }
  };
  componentDidMount() {
    this.component.current.addEventListener("scroll", this.onScroll);
    this.button();
  }
  componentDidUpdate() {
    this.button();
  }
  componentWillUnmount() {
    this.component.current.removeEventListener("scroll", this.onScroll);
  }
  render() {
    const {current} = this.props;
    let style = {
      width: "20px",
      height: "30px"
    };
    return (
      <div
        className={classNames("Scrollable", this.props.className)}
        ref={this.component}
      >
        {this.props.center ? (
          <div className="center">{this.props.children}</div>
        ) : (
          this.props.children
        )}
        {this.state.scrollButton ? (
          <Fab
            className={classNames({top: this.state.top})}
            mode="primary"
            icon="chevron-up"
            iconMode={2}
            right="10px"
            bottom={this.state.bottom}
            iconStyle={style}
            onClick={() => {
              if (this.state.top) {
                this.component.current.scrollTo(0, 160);
              } else {
                this.component.current.scrollTo(0, 0);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    current: reduxState.navigation.current
  };
}
export default connect(mapStateToProps, {})(Scrollable);
