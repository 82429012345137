import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/scss/core/Tab.scss';

export class TabHeader extends Component {
  constructor(props) {
    super(props);
    this.tabHeader = React.createRef();
  }
  onClick = (e) => {
    const headers = this.tabHeader.current.parentNode;
    var index =  Array.prototype.slice.call(headers.childNodes).indexOf(this.tabHeader.current);
    const tab = this.tabHeader.current.closest('.Tab');
    var tabItems = tab.querySelectorAll('.TabItem');
    headers.childNodes.forEach((d,i)=>{
      if(index===i){
        d.classList.add('active');
      } else {
        d.classList.remove('active');
      }
    });
    if(!this.props.callback) {
      tabItems.forEach((d,i)=>{
        if(index===i){
          d.classList.remove('hide');
        } else {
        d.classList.add('hide');
        }
      });
    } else {
      tabItems.forEach((d,i)=>{
        d.classList.remove('hide');
      });
      this.props.callback();
    }
  }
  render() {
    return <div className='TabHeader' ref={this.tabHeader} onClick={this.onClick} style={this.props.style} >
      {this.props.children}
    </div>;
  }
}

export class TabItem extends Component {
  render() {
    return <div className='TabItem' style={this.props.style} >
      {this.props.children}
    </div>;
  }
}

class Tab extends Component {
  constructor(props) {
    super(props);
    this.tab = React.createRef();
  }
  componentDidMount() {
    this.tab.current.querySelector('.TabItem').classList.add('active');
    this.tab.current.querySelector('.TabHeader').classList.add('active');
  }
  render() {
    return <div className='Tab' ref={this.tab} style={this.props.style} >
      {this.props.children}
    </div>;
  }
}

function mapStateToProps(reduxState){
  return {
  }
}
export default connect(mapStateToProps,{})(Tab);
