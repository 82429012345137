import React, {Component} from "react";
import {connect} from "react-redux";
import Tab, {TabItem, TabHeader} from "./core/Tab";
import Accordion, {AccordionHeader} from "./core/Accordion";
import Product from "./Product";
import Banner from "./core/Banner";
import Modal from "./core/Modal";
import DownloadForm from "./forms/DownloadForm";
import BuyForm from "./forms/BuyForm";
import Icon from "./core/Icon";
import "../assets/scss/ShopView.scss";
import classNames from "classnames";
import {getStarHtml} from "../helpers/star";
import {setDownload, setBuy, setConradForm} from "../store/product/actions";
import {track} from "../helpers/ga";
import {__} from "../helpers/multilingual";

class ShopView extends Component {
  state = {
    tab: 0
  };
  track = () => {
    track("PageView", "ShopView", "ShopView", 1);
  };
  componentDidMount() {
    this.track();
    this.updateBanner(0);
  }
  setBuy = (pack, name, index) => {
    let link =
      __(
        "https://www.amazon.de/gp/aws/cart/add.html?AssociateTag=",
        this.props.context
      ) + this.props.buy;
    let i = 0;
    for (var props in pack) {
      if (props !== "buy_all") {
        pack[props].forEach((e, j) => {
          if ((name === "custom" && e.selected) || name !== "custom") {
            i++;
            link +=
              "&ASIN." +
              i +
              "=" +
              e.affiliate_product_reference_number +
              "&Quantity." +
              i +
              "=" +
              e.quantity;
          }
          if (e.add_on_product && e.add_on_product.selected) {
            i++;
            link +=
              "&ASIN." +
              i +
              "=" +
              e.add_on_product.affiliate_product_reference_number +
              "&Quantity." +
              i +
              "=" +
              1;
          }
        });
      }
    }
    this.props.setBuy(link);
  };
  loopProduct = (products, key, select) => {
    const {packs} = this.props;
    return products
      .filter(
        (value, index, self) =>
          self.findIndex(da => value.ean_number === da.ean_number) === index
      )
      .map((d, i) => {
        let badge;
        if (select === "custom") {
          Object.keys(packs)
            .filter(p => p !== "custom")
            .forEach((e, j) => {
              for (var props in packs[e]) {
                if (props !== "buy_all") {
                  packs[e][props].forEach((f, k) => {
                    if (d.ean_number === f.ean_number) {
                      badge = e;
                    }
                  });
                }
              }
            });
        }
        return (
          <Product
            key={key + "_" + i}
            data={d}
            select={select}
            badge={badge}
            onClick={() =>
              this.setBuy(
                packs[Object.keys(packs)[this.state.tab]],
                Object.keys(packs)[this.state.tab],
                this.state.tab
              )
            }
          />
        );
      });
  };
  updateBanner = i => {
    track("Package", "Click", Object.keys(this.props.packs)[i], 1);
    this.setState({tab: i}, () => {
      const {packs} = this.props;
      this.setBuy(packs[Object.keys(packs)[i]], Object.keys(packs)[i]);
    });
  };
  render() {
    const {packs, context} = this.props;
    const pack = Object.keys(packs)[this.state.tab];
    var tabBodyRenderer = [];
    var index = 0;
    for (var props in packs[pack]) {
      if (props !== "buy_all") {
        tabBodyRenderer.push(
          <div key={pack + "_" + props} className="section">
            <Accordion
              open={
                index === 0 ? true : window.innerWidth >= 1024 ? true : false
              }
            >
              <AccordionHeader>
                {props === "smart_assistants" ? "Sprachassistenten" : props}
              </AccordionHeader>
              <div className="products">
                {this.loopProduct(packs[pack][props], pack + "_" + props, pack)}
              </div>
            </Accordion>
          </div>
        );
        index++;
      }
    }
    return (
      <div className="ShopView">
        {this.props.download ? (
          <Modal
            background
            onClose={() => this.props.setDownload(false)}
            className="download"
          >
            <DownloadForm packs={packs[pack]} tab={pack} />
          </Modal>
        ) : (
          ""
        )}
        {this.props.conrad_form ? (
          <Modal
            background
            onClose={() => this.props.setConradForm(false)}
            className="download"
          >
            <BuyForm />
          </Modal>
        ) : (
          ""
        )}
        <Tab>
          <div className="sticky">
            <div className="tabHeaders">
              {Object.keys(packs).map((d, i) => {
                var name =
                  d === "best_price"
                    ? __("Bester Preis", context)
                    : d === "best_rating"
                    ? __("Beste Bewertung", context)
                    : d === "best_value"
                    ? __("Unsere Empfehlung", context)
                    : __("Benutzerdefiniert", context);
                var icon =
                  d === "best_price" ? (
                    <Icon name="euro-sign" />
                  ) : d === "best_rating" ? (
                    <Icon name="star" />
                  ) : d === "best_value" ? (
                    <Icon name="thumbs-up" />
                  ) : (
                    ""
                  );
                return (
                  <TabHeader
                    key={d + "_" + props + "_header_" + i}
                    callback={() => {
                      this.updateBanner(i);
                    }}
                  >
                    <p>
                      {icon} {name}
                    </p>
                  </TabHeader>
                );
              })}
            </div>
            <Banner>
              {Object.keys(packs)
                .filter(p => p === pack)
                .map((d, i) => {
                  var quantity = 0;
                  var rating = 0;
                  var price = 0;
                  for (var props in packs[d]) {
                    if (props !== "buy_all") {
                      packs[d][props].forEach((e, j) => {
                        if (
                          (pack === "custom" && e.selected) ||
                          pack !== "custom"
                        ) {
                          quantity += parseInt(e.quantity);
                          rating += e.rating * parseInt(e.quantity);
                          price += e.price * parseInt(e.quantity);
                        }
                        if (e.add_on_product && e.add_on_product.selected) {
                          quantity += 1;
                          rating += e.add_on_product.rating;
                          price += e.add_on_product.price;
                        }
                      });
                    }
                  }
                  var ratingH = (rating = rating / quantity);
                  if (quantity === 0) {
                    ratingH = 0;
                    rating = 0;
                  }
                  var starRenderer = [];
                  for (let k = 0; k < 5; k++) {
                    starRenderer.push(
                      <span key={d + "_stars_" + k}>
                        {getStarHtml(ratingH, 15)}
                      </span>
                    );
                    ratingH--;
                  }
                  return (
                    <div className="container" key={d + "_banner"}>
                      <div className="column">
                        <p>
                          {new Intl.NumberFormat("de-DE", {
                            style: "decimal"
                          }).format(rating.toFixed(1)) +
                            " / " +
                            new Intl.NumberFormat("de-DE", {
                              style: "decimal"
                            }).format("5.0")}
                        </p>
                        <div>{starRenderer}</div>
                      </div>
                      <div className="column">
                        <p>{quantity}</p>
                        <p>{__("Produkte", context)}</p>
                      </div>
                      <div className="column">
                        <p>
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          }).format(price)}
                          *
                        </p>
                        <p>{__("Gesamtpreis", context)}</p>
                      </div>
                    </div>
                  );
                })}
            </Banner>
            <p className="notice">
              {__(
                "Alle Produkte, die in diesen Paketen gezeigt werden, sind kompatibel mit",
                context
              )}{" "}
              {this.props.sa}
            </p>
          </div>
          <TabItem key={pack}>{tabBodyRenderer}</TabItem>
        </Tab>
        <p style={{fontWeight: "bolder"}}>
          *{" "}
          {__(
            "Die angezeigten Preise sind nicht aktuell. Die tatsächlichen Preise können Sie bei Amazon einsehen",
            context
          )}
        </p>
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    brand: reduxState.settings.brand,
    page: reduxState.navigation.page,
    packs: reduxState.product.packs,
    download: reduxState.product.download,
    buy: reduxState.settings.settings.buy,
    sa: reduxState.product.request.product_answers.os.smart_assistant_name,
    conrad_form: reduxState.product.conrad_form,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {setDownload, setBuy, setConradForm})(
  ShopView
);
