import React, {Component} from "react";
import classNames from "classnames";
import {connect} from "react-redux";
import "../assets/scss/QuestionOption.scss";
import {
  getCurrentQuestion,
  toggleOption,
  toggleOptionVariation,
  toggleOptionVariationAccordion
} from "../store/question/actions";
import {updateHistory, moveHistory} from "../store/navigation/actions";
import {setAttr} from "../store/product/actions";
import {track} from "../helpers/ga";
import {__} from "../helpers/multilingual";

import Icon from "./core/Icon";
import Button from "./core/Button";
import Modal from "./core/Modal";

class QuestionOption extends Component {
  constructor(props) {
    super(props);
    this.option = React.createRef();
    this.state = {
      modal: false
    };
  }
  onClick = () => {
    track(
      "Question Option",
      this.props.data.selected ? "Unselect" : "Select",
      this.props.data.name,
      this.props.data.selected ? -1 : 1
    );
    var {slug} = this.props.data;
    if (
      this.props.data.slug === "15343655262" ||
      this.props.data.slug === "15343655263"
    ) {
      this.props.setAttr("name", this.props.data.name);
      if (this.props.data.slug === "15343655263") {
        this.props.setAttr("smart_assistant_name", "Siri");
      }
    }
    if (slug === "0" || slug === "0-07e1a9b2-93b5-4572-99b2-8fca09f9d7bc") {
      this.props.setAttr("smart_assistant_required", true);
    }
    if (slug === "0") {
      this.props.setAttr("smart_assistant_present", true);
    }
    if (
      slug === "0-15ba9417-bf80-4aa1-8c96-ed02cd0cc2ed" ||
      slug === "0-edb61ef0-2518-4987-acbd-19e856d8f8a6" ||
      slug === "0-478af738-ff9c-4648-a8c2-9ec0e2e07fc9"
    ) {
      this.props.setAttr("smart_assistant_name", this.props.data.name);
    }
    var cur = this.props.getCurrentQuestion();
    if ((cur.category && !this.props.first) || this.props.prev) {
      if (!this.props.data.selected) {
        this.props.toggleOption(this.props.data.slug);
      }
      this.props.updateHistory(this.props.data.slug);
      this.props.moveHistory(1);
    } else {
      this.props.toggleOption(this.props.data.slug);
    }
    if (cur.category && !this.props.first) {
      let q = cur.options.find(option => option.slug === this.props.data.slug);
      this.props.question
        .find(question => question.slug === q.slug)
        .options.forEach(d => {
          if (d.selected) {
            this.props.updateHistory(d.slug);
          }
        });
    }
    this.props.updateHistory(this.props.data.slug);
    var that = this;
    setTimeout(() => {
      if (that.option.current) {
        const rect = that.option.current.getBoundingClientRect();
        const parent = that.option.current.closest(".Scrollable");
        const inViewport =
          rect.top + rect.height <= parent.clientHeight + parent.offsetTop;
        if (!inViewport) {
          parent.scrollTo(0, parent.scrollTop + rect.height);
        }
      }
    }, 100);
  };
  toggleOptionVariationAccordion = (option, variation, trigger) => {
    if (
      trigger === "153436552716" ||
      trigger === "153436552717" ||
      trigger === "153436552718"
    ) {
      option.variations.forEach((d, i) => {
        if (d.selected) {
          this.props.toggleOptionVariationAccordion(
            option.slug,
            d.slug,
            trigger
          );
        }
      });
    }
    this.props.toggleOptionVariationAccordion(option.slug, variation, trigger);
  };
  render() {
    const {context} = this.props;
    var variation = <div />;
    var border = 0;
    if (this.props.data.variations) {
      border =
        this.props.data.variations.findIndex(variation => variation.selected) +
        1;
      variation = (
        <div
          className={classNames("option-variations", {
            border: !border && this.props.validate
          })}
        >
          {this.props.data.variations.map((d, i) => {
            return (
              <div
                key={d.slug}
                className={classNames("option-variation", {marked: d.selected})}
                onClick={() => {
                  if (this.props.mode === "accordion") {
                    track(
                      "Question Option Variation",
                      d.selected ? "Unselect" : "Select",
                      this.props.data.name + " - " + d.name,
                      d.selected ? -1 : 1
                    );
                    this.toggleOptionVariationAccordion(
                      this.props.data,
                      d.slug,
                      this.props.trigger
                    );
                  } else {
                    track(
                      "Question Option Variation",
                      d.selected ? "Unselect" : "Select",
                      this.props.data.name + " - " + d.name,
                      d.selected ? -1 : 1
                    );
                    this.props.toggleOptionVariation(
                      this.props.data.slug,
                      d.slug
                    );
                  }
                }}
              >
                {__(d.name, context)}
              </div>
            );
          })}
          {this.props.collapsable ? (
            <div
              className={classNames("option-variation", {marked: true})}
              onClick={this.onClick}
            >
              {__("Schließen", context)}
            </div>
          ) : (
            ""
          )}
          {this.props.trigger === "153436552716" ||
          this.props.trigger === "153436552717" ||
          this.props.trigger === "153436552718" ? (
            <Button
              className="info"
              mode="primary"
              icon="info"
              iconMode={2}
              onClick={() => {
                this.setState({modal: true}, () => {
                  this.option.current
                    .closest(".swiper-container")
                    .classList.add("disabled");
                });
                track("Bulb Info", "Show", this.props.data.name, -1);
              }}
            />
          ) : (
            ""
          )}
        </div>
      );
    }
    return (
      <div
        className={classNames("option-wrapper", this.props.className)}
        ref={this.option}
      >
        <div
          className={classNames("Option", {marked: this.props.data.selected})}
          onClick={this.onClick}
        >
          {this.props.data.description !== undefined &&
          this.props.data.description !== "" ? (
            <div
              className="flip"
              onMouseEnter={() =>
                track("Question Option", "Hover", this.props.data.name, 1)
              }
            >
              <div className="flip-inner">
                <div className="img">
                  <img src={this.props.data.images[0]} alt="" />
                </div>
                <div className="description">
                  <span>{__(this.props.data.description, context)}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="img">
              <img src={this.props.data.images[0]} alt="" />
            </div>
          )}
          <div className="title">
            {this.props.data.description !== undefined &&
            this.props.data.description !== "" ? (
              <span className="fingerprint">
                <Icon
                  name="fingerprint"
                  width="30px"
                  height="14px"
                  onClick={e => {
                    e.stopPropagation();
                    var me2 = e.target;
                    var me = me2
                      .closest(".Option")
                      .querySelector(".flip-inner");
                    me.classList.add("hover");
                    me2.classList.add("hover");
                    setTimeout(() => {
                      me.classList.remove("hover");
                      me2.classList.remove("hover");
                    }, 5000);
                    track(
                      "Question Option",
                      "Fingerprint",
                      this.props.data.name,
                      1
                    );
                  }}
                />
              </span>
            ) : (
              ""
            )}
            <span className="name">
              {this.props.data.name === "SmartAssistant"
                ? __("Sprachassistenten", context)
                : __(this.props.data.name, context)}
            </span>
            {this.props.data.selected ? (
              <span className="checkIcon">
                <Icon name="check" width="12px" height="12px" />
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {this.props.data.selected ? variation : ""}
        {this.state.modal &&
        (this.props.trigger === "153436552716" ||
          this.props.trigger === "153436552717" ||
          this.props.trigger === "153436552718") ? (
          <Modal
            onClose={() => {
              this.setState({modal: false}, () =>
                this.option.current
                  .closest(".swiper-container")
                  .classList.remove("disabled")
              );
              track("Bulb Info", "Hide", this.props.data.name, -1);
            }}
          >
            <table className="infoBox" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>{__("Glühlampe:", context)}</th>
                  <th>{__("Lumen:", context)}</th>
                  <th>{__("LED:", context)}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{__("40 W", context)}</td>
                  <td>{__("450", context)}</td>
                  <td>{__("4-6 W", context)}</td>
                </tr>
                <tr>
                  <td>{__("60 W", context)}</td>
                  <td>{__("800", context)}</td>
                  <td>{__("6-9 W", context)}</td>
                </tr>
                <tr>
                  <td>{__("75 W", context)}</td>
                  <td>{__("1100", context)}</td>
                  <td>{__("10 W", context)}</td>
                </tr>
                <tr>
                  <td>{__("100 W", context)}</td>
                  <td>{__("1600", context)}</td>
                  <td>{__("13 W", context)}</td>
                </tr>
                <tr>
                  <td>
                    <Icon name="lightbulb-r" />
                  </td>
                  <td>{__("W = Watt", context)}</td>
                  <td>
                    <Icon name="lightbulb" />
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal>
        ) : (
          ""
        )}
      </div>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    first: reduxState.navigation.first,
    question: reduxState.question.questions,
    prev: reduxState.navigation.prev,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {
  setAttr,
  getCurrentQuestion,
  toggleOption,
  updateHistory,
  moveHistory,
  toggleOptionVariation,
  toggleOptionVariationAccordion
})(QuestionOption);
