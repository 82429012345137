import React, {Component} from "react";
import {connect} from "react-redux";
import "../assets/scss/QuantityView.scss";
import QuantityOption from "./QuantityOption";
import {setRequest} from "../store/product/actions";
import {requestRemoveProduct, setConrad} from "../store/product/actions";
import {track} from "../helpers/ga";
import {__} from "../helpers/multilingual";

class QuantityView extends Component {
  constructor(props) {
    super(props);
    this.component = React.createRef();
  }
  track = () => {
    track("PageView", "QuantityView", "QuantityView", 1);
  };
  setRequest = () => {
    var request = [];
    var categories = this.component.current.children;
    if (categories[0].classList.contains("noSections")) {
      return;
    }
    for (var i = 0; i < categories.length; i++) {
      var product = [];
      var products = categories[i].children[1].children;
      for (var j = 0; j < products.length; j++) {
        let slug = products[j].dataset.slug.split("_");
        let p = {
          category_id: products[j].dataset.slug,
          quantity: products[j].dataset.quantity
        };
        if (slug.length === 3) {
          p.light_type = slug[0];
          p.wattages = {
            category_id: slug[2]
          };
          p.category_id = slug[1];
        }
        product.push(p);
      }
      var category = {};
      var name = categories[i].dataset.name.toLowerCase();
      if (name === "sprachassistenten") {
        name = "smart_assistants";
      }
      category[name] = {
        other_products: {
          products: product
        }
      };
      request.push(category);
    }
    this.props.setRequest(request);
  };
  componentDidMount() {
    this.props.setConrad(
      this.props.question
        .find(
          question => question.slug === "0-81a2f4ae-b210-44fe-9050-018dc36a4318"
        )
        .options.filter(d => d.selected).length > 0
    );
    this.track();
    this.setRequest();
  }
  componentDidUpdate() {
    this.setRequest();
  }

  render() {
    const {context} = this.props;
    var sectionsRenderer = [];
    this.props.question
      .find(question => question.category)
      .options.forEach((d, i, q) => {
        let section_name = d.name;
        if (d.name === "SmartAssistant") {
          section_name = "Sprachassistenten";
        }
        let optionsRenderer = [];
        if (d.selected) {
          var a = this.props.question.findIndex(
            question => question.slug === q[i].slug
          );
          var b;
          if (q[i + 1]) {
            b = this.props.question.findIndex(
              question => question.slug === q[i + 1].slug
            );
          } else {
            b = this.props.question.length;
          }
          this.props.question
            .filter((question, index) => index >= a && index < b)
            .forEach(question => {
              let options = question.options;
              if (question.template.name !== "accordion") {
                for (let j = 0; j < options.length; j++) {
                  if (
                    options[j].selected &&
                    options[j].final &&
                    options[j].variations === undefined
                  ) {
                    optionsRenderer.push(
                      <QuantityOption
                        key={options[j].slug}
                        data={options[j]}
                        section={section_name}
                        callback={() =>
                          this.props.requestRemoveProduct(
                            options[j].slug,
                            question,
                            q[i]
                          )
                        }
                      />
                    );
                  } else if (
                    options[j].selected &&
                    options[j].variations !== undefined
                  ) {
                    var variations = options[j].variations;
                    for (let k = 0; k < variations.length; k++) {
                      if (
                        variations[k].selected &&
                        variations[k].final &&
                        variations[k].variations === undefined
                      ) {
                        optionsRenderer.push(
                          <QuantityOption
                            key={variations[k].slug}
                            data={variations[k]}
                            section={section_name}
                            callback={() =>
                              this.props.requestRemoveProduct(
                                variations[k].slug,
                                question,
                                q[i]
                              )
                            }
                          />
                        );
                      }
                    }
                  }
                }
              } else {
                for (let j = 0; j < options.length; j++) {
                  let variations = options[j].variations;
                  for (let k = 0; k < variations.length; k++) {
                    if (
                      variations[k].selected &&
                      variations[k].final &&
                      variations[k].variations === undefined
                    ) {
                      optionsRenderer.push(
                        <QuantityOption
                          key={variations[k].slug}
                          data={variations[k]}
                          section={section_name}
                          callback={() =>
                            this.props.requestRemoveProduct(
                              variations[k].slug,
                              question,
                              q[i]
                            )
                          }
                        />
                      );
                    } else if (
                      variations[k].selected &&
                      variations[k].variations !== undefined
                    ) {
                      let variationsAccordion = variations[k].variations;
                      for (let l = 0; l < variationsAccordion.length; l++) {
                        if (
                          variationsAccordion[l].selected &&
                          variationsAccordion[l].final &&
                          variationsAccordion[l].variations === undefined
                        ) {
                          optionsRenderer.push(
                            <QuantityOption
                              key={variationsAccordion[l].slug}
                              data={variationsAccordion[l]}
                              section={section_name}
                              callback={() =>
                                this.props.requestRemoveProduct(
                                  variationsAccordion[l].slug,
                                  question,
                                  q[i]
                                )
                              }
                            />
                          );
                        }
                      }
                    }
                  }
                }
              }
            });
          sectionsRenderer.push(
            <div key={d.slug} className="category" data-name={section_name}>
              <div className="title">
                <span>{__(section_name, context)}</span>
              </div>
              <div className="optionBox">{optionsRenderer}</div>
            </div>
          );
        }
      });

    return (
      <div className="QuantityView">
        {sectionsRenderer.length ? (
          <h1 className="title">
            {__(
              "Wählen Sie die Anzahl der gewünschten Produkte pro Kategorie aus",
              context
            )}
          </h1>
        ) : (
          ""
        )}
        <div ref={this.component}>
          {sectionsRenderer.length ? (
            sectionsRenderer
          ) : (
            <h1 className="title noSections">
              {__(
                "Keine Produkte ausgewählt, bitte starten Sie den Konfigurator neu!",
                context
              )}
            </h1>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    current: reduxState.navigation.current,
    question: reduxState.question.questions,
    shop: reduxState.product.request.product_answers.shop_category_name,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {
  setRequest,
  requestRemoveProduct,
  setConrad
})(QuantityView);
