import React, { Component } from 'react';
import { Document, Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

Font.register({ family: 'Brandon Text Bold', src: './fonts/Brandon Text W01 Bold/Brandon Text W01 Bold.ttf', fontWeight: 'bold' });
class Pdf extends Component {
  render() {
    const { pdf, customer, products } = this.props;
    const date = new Date();
    const styles = StyleSheet.create({
      body: {
        padding: 35,
        fontSize: 14,
      },
      slogan: {
        textAlign: 'center',
        fontSize: 10,
        marginTop: 5,
        marginBottom: 12,
        color: '#222222'
      },
      addressContainer: {
        flexDirection: 'row',
      },
      address: {
        flexGrow: 1
      },
      text: {
        fontSize: 12,
      },
      date: {
        fontSize: 12,
      },
      customer: {
        marginTop: 30,
      },
      table: {
        flexDirection: 'column',
      },
      tableRow: {
        flexDirection: 'row',
      },
      tableItem: {
        fontSize: 10,
        textAlign: 'center',
        flexBasis: (85/4)+'%',
        paddingTop: 10,
        paddingBottom: 10,
      },
      tableItemFirst: {
        fontSize: 10,
        textAlign: 'left',
        flexBasis: '5%',
        paddingTop: 10,
        paddingLeft: 5,
      },
      tableItemLast: {
        fontSize: 10,
        textAlign: 'right',
        flexBasis: '10%',
        paddingTop: 10,
        paddingRight: 5,
      },
      tableRowWide: {
        flexDirection: 'row',
        marginBottom: 10,
      },
      tableItemWide: {
        fontSize: 12,
        textAlign: 'left',
      },
      sumItemFirst: {
        fontSize: 12,
        textAlign: 'right',
        flexBasis: '80%',
        paddingRight: 20
      },
      sumItem: {
        fontSize: 12,
        textAlign: 'left',
        flexBasis: '20%',
      },
      footer: {
        flexDirection: 'row',
        marginTop: 50,
        bottom: 35,
        left: 35,
        right: 35
      }
    });
    const Table = styled.View`
      border: 1px solid #222222;
      margin-top: 50px;
    `;
    const Sum = styled.View`
      margin-top: 25px;
      padding: 5px;
    `;
    const Logo = styled.Image`
      height: 50px;
      width: 250px;
      margin: 0 auto;
    `;
    let sum=0;
    products.forEach((d,i)=>{
      sum+=d.price*d.quantity;
    })
    var proNew = [];
    for (var i = 0; i < products.length; i+=7) {
      proNew.push(products.slice(i,i+7));
    }
    let count = 1;
    return <Document>
      <Page style={styles.body}>
        <Logo src={pdf.logo} fixed />
        <Text style={styles.slogan} fixed>{pdf.slogan}</Text>
        <View style={styles.addressContainer} fixed>
          <View style={styles.address}>
            {
              pdf.address.map((d,i)=>{
                return <Text style={styles.text}>{d}</Text>
              })
            }
          </View>
          <Text style={styles.date}>Datum: {date.toLocaleDateString('de-DE', {  year: 'numeric', month: 'numeric', day: 'numeric' })}</Text>
        </View>
        <View style={styles.customer} fixed>
          <Text style={styles.text}>{customer.name}</Text>
          {
            customer.address.map((d,i)=>{
              return <Text style={styles.text}>{d}</Text>
            })
          }
        </View>
        {
          proNew.map((c,i)=>(
            <Table break={i>0}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableItemFirst, {fontFamily: 'Brandon Text Bold',borderBottom: '1 solid #222222',borderRight: '1 solid #222222'}]}>Pos.</Text>
                <Text style={[styles.tableItem, {fontFamily: 'Brandon Text Bold',borderBottom: '1 solid #222222',borderRight: '1 solid #222222'}]}>EAN Nummer</Text>
                <Text style={[styles.tableItem, {fontFamily: 'Brandon Text Bold',borderBottom: '1 solid #222222',borderRight: '1 solid #222222'}]}>Name</Text>
                <Text style={[styles.tableItem, {fontFamily: 'Brandon Text Bold',borderBottom: '1 solid #222222',borderRight: '1 solid #222222'}]}>Menge</Text>
                <Text style={[styles.tableItem, {fontFamily: 'Brandon Text Bold',borderBottom: '1 solid #222222',borderRight: '1 solid #222222'}]}>Preis</Text>
                <Text style={[styles.tableItemLast, {fontFamily: 'Brandon Text Bold',borderBottom: '1 solid #222222'}]}>Gesamtpreis</Text>
              </View>
              {
                c.map((d,j)=>{
                  return <>
                    <View key={'products_'+d.ean} style={styles.tableRow}>
                      <Text style={[styles.tableItemFirst,{borderRight: '1 solid #222222'}]}>{count++}</Text>
                      <Text style={[styles.tableItem,{borderRight: '1 solid #222222'}]}>{d.ean}</Text>
                      <Text style={[styles.tableItem,{borderRight: '1 solid #222222'}]}>{d.name}</Text>
                      <Text style={[styles.tableItem,{borderRight: '1 solid #222222'}]}>{d.quantity}</Text>
                      <Text style={[styles.tableItem,{borderRight: '1 solid #222222'}]}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(d.price)}</Text>
                      <Text style={styles.tableItemLast}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(d.price*d.quantity)}</Text>
                    </View>
                  </>
                })
              }
            </Table>
          ))
        }
        <Sum>
          <View style={styles.tableRow}>
            <Text style={styles.sumItemFirst}>Gesamt Brutto.:</Text>
            <Text style={styles.sumItem}>
              {
                new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sum)
              }
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.sumItemFirst}>davon 19% MwSt.:</Text>
            <Text style={styles.sumItem}>
              {
                new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sum/1.19*0.19)
              }
            </Text>
          </View>
        </Sum>
        <View style={styles.footer} fixed>
          {
            pdf.footer.map((d,i,q)=>{
              return <View style={{flexBasis:(100/q.length)+'%'}} >
                {
                  d.map((e,j)=>{
                    return <Text style={styles.text}>{e}</Text>
                  })
                }
              </View>
            })
          }
        </View>
      </Page>
    </Document>;
  }
}
export default Pdf;
