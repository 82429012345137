import React, {Component} from "react";
import {connect} from "react-redux";
import "../assets/scss/WelcomeView.scss";
import Scrollable from "./core/Scrollable";
import Button from "./core/Button";
import {startConfig} from "../store/navigation/actions";
import {withCookies} from "react-cookie";
import {init, track} from "../helpers/ga";
import {__} from "../helpers/multilingual";
function WelcomeView({welcome, analytics, context, startConfig, cookies}) {
  return (
    <div className="WelcomeView">
      <Scrollable center>
        <h1>{__(welcome.question, context)}</h1>
        <div className="description">{__(welcome.description, context)}</div>
        <Button
          onClick={() => {
            var smt = cookies.get("smt");
            startConfig();
            if (smt.statistics.ga) {
              init(analytics);
              track("WelcomeScreen", "click", "click", 1);
            }
          }}
        >
          {__("Jetzt Konfigurieren", context)}
        </Button>
        <div className="description">{__("IoT services:", context)}</div>
        <div className="description">
          {__(
            "Zusammen mit den Smarthome-Lösungen stellen wir Zusammen mit den Smarthome-Lösungen & den IoT-Diensten der Conrad Connect-Plattform können Sie jetzt ganz einfach zusätzliche Dienste über Apps & Services von über 100 Marken aktivieren. Implementieren Sie kostenlos einfache Automatisierungsprojekte!",
            context
          )}
        </div>
        <div className="description">
          {__(
            "Der Benutzer ist selbst für die Prüfung der Bedingungen, der Einbau und Integration des Systems vor Ort verantwortlich. Der Konfigurator stellt ein Smart Home System basierend auf System-/Produktkompatibilität zusammen. Da die Wohnsituation bzw. Bedingungen vor Ort vom Konfigurator nicht berücksichtigt werden, müssen diese selbst vom Benutzer geprüft werden.",
            context
          )}
        </div>
      </Scrollable>
    </div>
  );
}
function mapStateToProps(reduxState) {
  return {
    welcome: reduxState.navigation.welcome,
    analytics: reduxState.settings.settings.analytics,
    context: reduxState.multilingual.translations
  };
}
export default withCookies(
  connect(mapStateToProps, {startConfig})(WelcomeView)
);
