import React, {Component} from "react";
import {connect} from "react-redux";
import "../assets/scss/QuestionView.scss";
import {getCurrentQuestion} from "../store/question/actions";
import Default from "./template-question";
import Accordion from "./template-question/Accordion";
import Scrollable from "./core/Scrollable";
import {track} from "../helpers/ga";
import {__} from "../helpers/multilingual";

class QuestionView extends Component {
  track = () => {
    track(
      "PageView",
      "QuestionView",
      this.props.getCurrentQuestion().question,
      1
    );
  };
  componentDidMount() {
    this.track();
  }
  componentDidUpdate() {
    this.track();
  }
  templateSwitcher = (template, options) => {
    switch (template.name) {
      case "accordion":
        return <Accordion options={options} settings={template} />;
      default:
        return <Default options={options} />;
    }
  };
  render() {
    const {
      question,
      options,
      template,
      description,
      hint,
      slug,
      category
    } = this.props.getCurrentQuestion();
    const {context} = this.props;
    return (
      <div className="QuestionView">
        <h1 className="title">
          {slug === "15343655265"
            ? this.props.os.smart_assistant_present
              ? __("Welchen Sprachassistenten besitzen Sie?", context)
              : this.props.os.smart_assistant_required
              ? __(
                  "Welchen Sprachassistenten würden Sie gerne auswählen?",
                  context
                )
              : __(question, context)
            : __(question, context)}
        </h1>
        <div>
          <div className="description">
            {category && !this.props.first
              ? __(
                  'Sie haben nun die Möglichkeit weitere Kategorien auszuwählen oder Ihre Konfiguration mit "Fertig" abzuschließen',
                  context
                )
              : __(hint, context)}
            {hint ? (
              <>
                <br />
              </>
            ) : (
              ""
            )}
            {__(description, context)}
          </div>
          {this.templateSwitcher(template, options)}
        </div>
      </div>
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    current: reduxState.navigation.current,
    question: reduxState.question.questions,
    os: reduxState.product.request.product_answers.os,
    first: reduxState.navigation.first,
    context: reduxState.multilingual.translations
  };
}
export default connect(mapStateToProps, {getCurrentQuestion})(QuestionView);
