import React, { Component } from 'react';
import Scrollable from './core/Scrollable';
import '../assets/scss/Loader.scss';

class Loader extends Component {
  render(){
    return <div className='Loader'>
      <Scrollable center>
        {this.props.children}
      </Scrollable>
    </div>;
  }
}
export default Loader;
